import { isBefore } from 'date-fns'
import { createContext, useState, useContext } from 'react'
import { api } from './api'
import { neutralizeDate } from '@/utils/normalize'

interface BillingContext {
  payerSelected: Payer
  setPayerSelected: (v: Payer) => void
  method: 'charge' | 'confirmation'
  setMethod: (method: 'charge' | 'confirmation') => void
  payersQueued: {
    payer_id: number
    queue_id: number
    document_number: string
    name: string
    total: string
    blocked?: boolean
  }[]
  setPayersQueued: (data: BillingContext['payersQueued']) => void
  selectedGroup: number | null
  setSelectedGroup: (v: number | null) => void
  loading: boolean
  setLoading: (v: boolean) => void
  queue: Queue
  refreshList: (params?: any) => Promise<void>
  selectPayer: (payer_id: number) => Promise<void>
  scheduleId: number | null
  config: any
  setConfig: (v: any) => void
}

const Billing = createContext<BillingContext>({} as BillingContext)

export const BillingProvider: React.FC = ({ children }) => {
  const [payerSelected, setPayerSelected] = useState({} as Payer)
  const [method, setMethod] = useState<'confirmation' | 'charge'>('charge')
  const [payersQueued, setPayersQueued] = useState<
    BillingContext['payersQueued']
  >([])

  const [scheduleId, setScheduleId] = useState<number | null>(null)
  const [queue, setQueue] = useState<Queue>({} as any)
  const [selectedGroup, setSelectedGroup] = useState<null | number>(null)

  const [config, setConfig] = useState({
    visible: true,
    params: {} as any
  })

  const [loading, setLoading] = useState(false)

  const refreshList = async (load = true) => {
    if (load) {
      setLoading(true)
    }
    try {
      const { data } = await api.get('/queue_payers', {
        params: config.params
      })
      setQueue(data.queue)

      const { data: schedules } = await api.get<
        { id: number; payer_id: number; date: string }[]
      >('/queue_schedules')
      const item = schedules.find(schedule =>
        isBefore(new Date(schedule.date), new Date())
      )
      if (item) {
        selectPayer(item.payer_id)
        setScheduleId(item.id)
      } else {
        setScheduleId(null)
      }
    } catch (err) {
      setQueue({} as any)
      setPayersQueued([])
    }
    setLoading(false)
  }

  const selectPayer = async (payer_id: number) => {
    setLoading(true)
    try {
      const { data } = await api.get(`/payers/${payer_id}`)
      setPayerSelected(data)
    } catch (err) {
      //
    }
    setLoading(false)
  }

  return (
    <Billing.Provider
      value={{
        payerSelected,
        setPayerSelected,
        method,
        setMethod,
        payersQueued,
        setPayersQueued,
        selectedGroup,
        setSelectedGroup,
        refreshList,
        selectPayer,
        setLoading,
        loading,
        queue,
        scheduleId,
        config,
        setConfig
      }}
    >
      {children}
    </Billing.Provider>
  )
}

export const useBilling = (): BillingContext => {
  const context = useContext(Billing)

  if (!context) {
    throw new Error(
      'The hook useBilling must be used within an BillingProvider'
    )
  }

  return context
}
