import Lottie from 'react-lottie'
import { Container } from './styles'

import * as animationData from '@/assets/loading.json'
import { Spinner } from 'react-bootstrap'

export function LoadingPage(): JSX.Element {
  return (
    <Container>
      <Lottie
        options={{
          animationData,
          loop: true,
          autoplay: true
        }}
        height={200}
        width={200}
      />
      <h5>
        Carregando <Spinner animation="border" />
      </h5>
    </Container>
  )
}
