import useSWR from 'swr'
import axios from 'axios'

export const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_GATEWAY_URL
})

export function useFetch<Data = any, Error = any>(url: string) {
  //eslint-disable-line
  const { data, error, mutate } = useSWR<Data, Error>(
    url,
    async key => {
      const response = await api.get(key)

      return response.data
    },
    {
      refreshInterval: 60000
    }
  )

  return { data, error, mutate }
}

class Sdk {
  async getCompanies(): Promise<Company[]> {
    const { data } = await api.get('/companies')
    return data
  }

  async getPaymentTypes(): Promise<PaymentType[]> {
    const { data } = await api.get('/payment_types')
    return data
  }

  async getWriteOffTypes(): Promise<WriteOffType[]> {
    const { data } = await api.get('/write_off_types')
    return data
  }
}

export const sdk = new Sdk()
