import {
  dateIsValid,
  formatDate,
  neutralizeDate,
  normalizeDate
} from '@/utils/normalize'
import React, { useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import { format } from 'date-fns'

// import { Container } from './styles';

const DateView: React.FC<{
  title?: string
  value: any
  onChange?: (v: any) => void
  onBlur?: () => void
  filterDate?: (date: Date) => boolean
  considerHour?: boolean
  minDate?: Date
  disabled?: boolean
  required?: boolean
  showMonths?: boolean
}> = ({
  title,
  value,
  onChange,
  onBlur = () => {
    //
  },
  filterDate,
  considerHour = false,
  disabled,
  required = false,
  minDate,
  showMonths
}) => {
  const [valueRaw, setValueRaw] = useState(
    value && dateIsValid(value) ? format(new Date(value), 'dd/MM/yyyy') : ''
  )

  useEffect(() => {
    if (!value) {
      setValueRaw('')
    }
  }, [value])

  useEffect(() => {
    if (!valueRaw) {
      setValueRaw(
        value && dateIsValid(value) ? format(new Date(value), 'dd/MM/yyyy') : ''
      )
    }
  }, [value, valueRaw])

  if (!onChange) {
    return <>{value ? formatDate(value, considerHour) : ''}</>
  }
  return (
    <ReactDatePicker
      title={title}
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      locale="br"
      filterDate={filterDate}
      selected={
        value && dateIsValid(new Date(value))
          ? neutralizeDate(new Date(value))
          : undefined
      }
      value={valueRaw}
      onChangeRaw={e => setValueRaw(normalizeDate(e.target.value))}
      minDate={minDate ? neutralizeDate(minDate) : undefined}
      onChange={e => {
        onChange(e ? new Date((e as Date).setHours(0)) : null)
        if (!e) {
          setValueRaw('')
        }
      }}
      onCalendarClose={onBlur}
      dateFormat={showMonths ? 'MM/yyyy' : 'dd/MM/yyyy'}
      showMonthYearPicker={showMonths}
      showFullMonthYearPicker={showMonths}
      placeholderText={showMonths ? 'MM/AAAA' : 'DD/MM/AAAA'}
      className="form-control"
      disabled={disabled}
      required={required}
      isClearable
      clearButtonTitle="Limpar data"
    />
  )
}

export default DateView
