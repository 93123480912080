import { IRoute, useMenu } from '@/hooks/useMenu'
import { useRouter } from 'next/router'
import React from 'react'
import { Dropdown as BootstrapDropdown } from 'react-bootstrap'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'

interface IProps {
  route: IRoute
  open: boolean
  setOpen: () => void
}

export const Dropdown: React.FC<IProps> = ({ route, open, setOpen }) => {
  const router = useRouter()

  const { onChangeActive } = useMenu()

  return (
    <div className="drop_container">
      <button
        type="button"
        onClick={() => setOpen()}
        className={open ? 'active' : ''}
      >
        {route.icon}
        <strong className="desc">{route.title}</strong>
        <span className="arrow">
          {open ? <IoIosArrowDown /> : <IoIosArrowUp />}
        </span>
      </button>
      {open && (
        <div>
          {route?.options?.map(option => {
            if (option.options) {
              return (
                <BootstrapDropdown className="mt-0 mb-0 p-0" key={option.route}>
                  <BootstrapDropdown.Toggle variant="none">
                    {option.title}
                  </BootstrapDropdown.Toggle>
                  <BootstrapDropdown.Menu style={{ zIndex: 9999999999999 }}>
                    {option.options.map(o => (
                      <a
                        key={o.route}
                        href={o.route}
                        onClick={e => {
                          e.preventDefault()
                          router.push(o.route)
                          onChangeActive(o.route)
                        }}
                      >
                        {o.title}
                      </a>
                    ))}
                  </BootstrapDropdown.Menu>
                </BootstrapDropdown>
              )
            }
            return (
              <a
                href={option.route}
                onClick={e => {
                  e.preventDefault()
                  router.push(option.route)
                  onChangeActive(option.route)
                }}
                key={option.title}
              >
                {option.title}
              </a>
            )
          })}
        </div>
      )}
    </div>
  )
}
