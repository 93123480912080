import { Header } from '@/components/ui/Header'
import Menu from '@/components/ui/Menu'
import { SubHeader } from '@/components/ui/SubHeader'
import { Toolbar } from '@/components/ui/Toolbar'
import React from 'react'

import { Container, Content, ScrollBox } from './styles'

export const LayoutSystem: React.FC = ({ children }) => {
  return (
    <Container>
      <Header />
      <Toolbar />
      <Content>
        <Menu />
        <div className="operate">
          <SubHeader />
          <ScrollBox className="scroll-box-item">{children}</ScrollBox>
        </div>
      </Content>
    </Container>
  )
}
