import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  background: #fff;
  z-index: 999;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 600px;
  border-radius: 1rem;
  .jodit-container {
    height: 40vh !important;
    min-height: 200px !important;
  }
  strong.title-page {
    display: flex;
    justify-content: space-between;
    background: #444;
    color: #eee;
    padding: 0.4rem 1rem;
    width: 100%;
    font-size: 1rem;
    border-radius: 1rem 1rem 0 0;
    button {
      color: #fff;
    }
  }
  input {
    width: 100%;
    border: none;
    padding: 0.4rem 0.6rem;
    font-size: 1rem;
  }
  .attach-item {
    font-size: 1rem;
    padding: 0.2rem 0.8rem;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 1rem;
    margin: 0.3rem;
    button {
      margin-left: 0.6rem;
    }
  }
`
