import styled from 'styled-components'

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
`
export const Content = styled.div`
  flex: 1;
  display: flex;
  height: 0vh;
  -webkit-box-align: stretch;
  position: relative;
  .menu-open + .operate {
    width: calc(100% - 260px);
  }
  .menu-close + .operate {
    width: calc(100% - 60px);
  }
  @media screen and (max-width: 650px) {
    .menu-open + .operate {
      width: 100%;
    }
    .menu-close + .operate {
      width: 100%;
    }
  }
  .operate {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
`

export const ScrollBox = styled.div`
  overflow-y: auto;
  flex: 1;
  width: 100%;
  background: #fdfdfd;
  color: ${props => (props.theme.name === 'light' ? '#444' : '#eee')};
  position: relative;
  padding: 1.4rem 1.5rem;
  > div,
  > main {
    display: flex;
    flex-direction: column;
    background: #fdfdfd;
    flex: 1;
  }
`
