import styled from 'styled-components'

export const Container = styled.div`
  background: #f3f2f2;
  padding: 0 1.2rem;
  height: 42px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2 {
    font-size: 1.2rem;
    color: ${props => (props.theme.name === 'light' ? '#79858c' : '#eee')};
    font-weight: 500;
    margin: 0;
  }
  .breadcrumb {
    margin: 0;
    background: transparent;
  }
  @media screen and (max-width: 650px) {
    .breadcrumb {
      display: none;
    }
  }
`
