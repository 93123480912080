import { api } from '@/hooks/api'
import { useAuth } from '@/hooks/useAuth'
import { bfcDigital } from '@/services/bfcDigital'
import dynamic from 'next/dynamic'
import React, { useEffect, useState } from 'react'
import { Button, Form, Spinner } from 'react-bootstrap'
import { FaTimesCircle } from 'react-icons/fa'
import { IoMdAttach } from 'react-icons/io'
import { IoSend } from 'react-icons/io5'
import { toast } from 'react-toastify'

import { Container } from './styles'

const importJodit = () => import('jodit-react')

const Jodit = dynamic(importJodit, {
  ssr: false
})

export const EmailSender: React.FC<{
  onHide: () => void
}> = ({ onHide }) => {
  const [loading, setLoading] = useState(false)
  const [content, setContent] = useState('')
  const [subject, setSubject] = useState('')
  const [attachs, setAttachs] = useState<File[]>([])
  const [selectedTemplate, setSelectedTemplate] = useState('')
  const isSSR = typeof window === 'undefined'
  const [to, setTo] = useState<string[]>([])
  const [digitedTo, setDigitedTo] = useState('')
  const [templates, setTemplates] = useState<EmailTemplates[]>([])
  useEffect(() => {
    api
      .get('/email_templates?all=1')
      .then(res => setTemplates(res.data))
      .catch(() => {
        //
      })
  }, [])

  useEffect(() => {
    if (selectedTemplate && selectedTemplate !== 'no-template') {
      const item = templates.find(t => String(t.id) === selectedTemplate)
      if (item) {
        setContent(item.content)
      }
    }
  }, [selectedTemplate])

  const { user } = useAuth()

  const resetForm = () => {
    setTo([])
    setDigitedTo('')
    setContent('')
    setSelectedTemplate('')
    setSubject('')
    setAttachs([])
  }

  async function handleSubmit() {
    setLoading(true)
    try {
      const obj = new FormData()
      obj.append(
        'emails',
        to.map(e => e.split(' ').join('').toLowerCase()).join(',')
      )
      obj.append('message', content)
      obj.append('subject', subject)
      obj.append('origin', 'notification')
      obj.append('user_id', String(user?.id))
      attachs.forEach(attachment => obj.append('attachs', attachment))
      await bfcDigital.post('/charge-v2-avulse/avulses', obj, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      toast.info('E-mail enviado para processamento')
      resetForm()
      onHide()
    } catch (err) {
      //
    }
    setLoading(false)
  }

  const config = {
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    readonly: loading,
    buttons: [
      'bold',
      'italic',
      '|',
      'ul',
      'ol',
      'eraser',
      '|',
      'font',
      'fontsize',
      'brush',
      'paragraph',
      'align',
      '---',
      'image',
      'table',
      '|',
      'link',
      'hr',
      'copyformat',
      '---',
      '|',
      'dots'
    ],
    height: '30vh'
  }

  const verifyEmails = () => {
    if (digitedTo.indexOf(';') > -1) {
      setTo([...to, ...digitedTo.split(';').filter(i => i !== '')])
      setDigitedTo('')
    } else if (digitedTo.indexOf(' ') > -1) {
      setTo([...to, ...digitedTo.split(' ').filter(i => i !== '')])
      setDigitedTo('')
    }
  }

  const hasEmail = () => {
    if (digitedTo.indexOf('@') > -1 && digitedTo.indexOf(';') === -1) {
      setTo([...to, digitedTo])
      setDigitedTo('')
    } else {
      verifyEmails()
    }
  }

  useEffect(() => {
    verifyEmails()
  }, [digitedTo])

  return (
    <Container>
      <strong className="title-page">
        <span>Nova Mensagem</span>
        <button type="button" onClick={onHide} disabled={loading}>
          <FaTimesCircle className="m-0" />
        </button>
      </strong>
      <div className="pl-3 pr-3">
        <label
          htmlFor="to-mail"
          className="d-flex align-items-center pb-0 mb-2 mt-2"
        >
          <small>Para:</small>
          <div className="d-flex flex-wrap">
            {to.map(t => (
              <span
                className="d-flex align-items-center pl-2 pr-2 radius-4 m-1 small bg-gray"
                key={t}
              >
                {t}{' '}
                <button
                  type="button"
                  className="ml-2 small"
                  onClick={() => setTo(to.filter(i => i !== t))}
                  disabled={loading}
                >
                  <FaTimesCircle className="m-0" />
                </button>
              </span>
            ))}
            <input
              type="text"
              id="to-mail"
              value={digitedTo}
              onChange={e => setDigitedTo(e.target.value.toLowerCase())}
              onBlur={hasEmail}
              style={{ flex: 1 }}
              className="w-100"
              disabled={loading}
            />
          </div>
        </label>
        <hr className="m-0" />
        <label
          htmlFor="subject-mail"
          className="d-flex align-items-center pb-0 mt-2 mb-2"
        >
          <small>Assunto:</small>
          <input
            type="text"
            value={subject}
            onChange={e => setSubject(e.target.value)}
            id="subject-mail"
            disabled={loading}
          />
        </label>
        <hr className="m-0" />
        <div className="mt-2 mb-3">
          {!isSSR && (
            <React.Suspense fallback={<div>Loading RTE</div>}>
              <Jodit
                value={selectedTemplate ? content : 'Escolha um Template'}
                onBlur={e => setContent(e)}
                config={{
                  ...config,
                  readonly: loading || !selectedTemplate
                }}
              />
            </React.Suspense>
          )}
        </div>
        {attachs.length > 0 && (
          <div className="d-flex align-items-center flex-wrap mb-3">
            {attachs?.map(attach => (
              <div className="attach-item" key={attach.name}>
                <span>{attach.name}</span>
                <button
                  type="button"
                  onClick={() =>
                    setAttachs(attachs.filter(a => a.name !== attach.name))
                  }
                  disabled={loading}
                >
                  <FaTimesCircle className="m-0" />
                </button>
              </div>
            ))}
          </div>
        )}

        <div className="d-flex align-items-center justify-content-between mb-3">
          <div className="d-flex align-items-center">
            <Button
              type="button"
              onClick={handleSubmit}
              disabled={!selectedTemplate || !content || loading}
            >
              {loading ? <Spinner animation="border" /> : <IoSend />} Enviar
            </Button>
            <label htmlFor="input-attach" className="ml-3 mb-0 c-pointer">
              <IoMdAttach className="m-0" />
              <input
                type="file"
                id="input-attach"
                style={{ display: 'none' }}
                onChange={e => {
                  if (e?.target?.files) {
                    setAttachs([...attachs, e.target.files[0]])
                  }
                }}
                disabled={loading}
              />
            </label>
          </div>
          <Form.Group className="m-0 d-flex align-items-center">
            <Form.Label className="mb-0 pr-2">Template:</Form.Label>
            <Form.Control
              as="select"
              value={selectedTemplate}
              onChange={e => setSelectedTemplate(e.target.value)}
              disabled={loading}
            >
              <option value="">Escolher</option>
              <option value="no-template">Sem template</option>
              {templates.map(template => (
                <option value={template.id} key={template.id}>
                  {template.title}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </div>
      </div>
    </Container>
  )
}
