import styled from 'styled-components'

export const Container = styled.header`
  background-image: url('/bg-header.jpg');
  background-repeat: no-repeat;
  background-color: #2b3c5b;
  background-size: cover;
  width: 100%;
  padding: 0;
  img {
    width: 100%;
    max-width: 30px;
  }
  button.theme_switcher {
    display: block;
    width: 100%;
    margin-top: 1rem;
    background: ${props => (props.theme.name === 'light' ? '#2b3c5b' : '#eee')};
    color: ${props => (props.theme.name === 'light' ? '#eee' : '#2b3c5b')};
    padding: 0.7rem 1rem;
    text-transform: uppercase;
    font-weight: 900;
  }
  > div.cont {
    color: #e4e4e4;
    padding: 0.8rem 1.5rem;
    height: 60px;
    h2 {
      button {
        color: #fff;
        font-size: 1.2rem;
        font-weight: 700;
      }
    }
    .widgets {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      button {
        outline: none !important;
        border: none !important;
        svg {
          font-size: 2rem;
        }
      }
      .dropdown-toggle::after {
        display: none;
      }
    }
  }
  .notifications-content {
    box-shadow: 0 0 5px 1px rgba(var(--jb7, 0, 0, 0), 0.0975);
    overflow: inherit !important;
    transform: translate3d(45px, 40px, 0px) !important;
    max-height: 350px !important;
    &::before {
      content: '';
      position: absolute;
      top: -7.5px;
      right: 50px;
      z-index: 9;
      border-left: 7.5px solid transparent;
      border-right: 7.5px solid transparent;
      border-bottom: 7.5px solid #fff;
    }
    .notifications-box {
      height: 350px;
      overflow: scroll;
      > div {
        strong {
          font-size: 0.85rem;
        }
        small {
          font-size: 0.8rem;
        }
        time {
          margin-top: 10px;
          font-size: 10px;
          text-align: right;
        }
      }
      > div:nth-child(even) {
        background: #f5f5f5;
      }
    }
  }

  @media screen and (max-width: 650px) {
    .company_logged {
      display: none;
    }
  }
`
