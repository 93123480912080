import { createContext, useState, useContext, useEffect } from 'react'

interface ThemeContext {
  theme: 'light' | 'dark'
  switchTheme: () => void
}

const Theme = createContext<ThemeContext>({} as ThemeContext)

export const ThemeProvider: React.FC = ({ children }) => {
  const [theme, setTheme] = useState<'dark' | 'light'>('dark')

  useEffect(() => {
    const t = localStorage.getItem('theme')
    if (t) {
      setTheme(t as 'light' | 'dark')
    } else {
      localStorage.setItem('theme', 'light')
      setTheme('light')
    }
  }, [])

  function switchTheme() {
    setTheme(theme === 'light' ? 'dark' : 'light')
    localStorage.setItem('theme', theme === 'light' ? 'dark' : 'light')
  }

  return (
    <Theme.Provider value={{ theme, switchTheme }}>{children}</Theme.Provider>
  )
}

export const useTheme = (): ThemeContext => {
  const context = useContext(Theme)

  if (!context) {
    throw new Error('The hook useTheme must be used within an ThemeProvider')
  }

  return context
}
