import React, { useEffect, useState, useRef } from 'react'

interface MinutesOnly {
  minutes: number
  seconds?: never
  onTimeChange?: (timeInSeconds: number) => void
  reset?: boolean
}

interface SecondsOnly {
  minutes?: never
  seconds: number
  onTimeChange?: (timeInSeconds: number) => void
  reset?: boolean
}

type Props = MinutesOnly | SecondsOnly

export default function Timer({
  minutes: minutesProp = 0,
  seconds: secondsProp = 0,
  onTimeChange,
  reset
}: Props): JSX.Element {
  const [minutes, setMinutes] = useState(minutesProp)
  const [seconds, setSeconds] = useState(secondsProp)

  const intervalRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (onTimeChange) {
      onTimeChange(minutes * 60 + seconds)
    }
  }, [minutes, seconds, onTimeChange])

  useEffect(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
    }

    intervalRef.current = setInterval(() => {
      if (seconds > 0) {
        setSeconds(prevSeconds => prevSeconds - 1)
      } else if (minutes > 0) {
        setMinutes(prevMinutes => prevMinutes - 1)
        setSeconds(59)
      } else {
        if (intervalRef.current) {
          clearInterval(intervalRef.current)
        }
      }
    }, 1000)

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [minutes, seconds])

  useEffect(() => {
    setMinutes(minutesProp)
    setSeconds(secondsProp)
  }, [reset, minutesProp, secondsProp])

  return (
    <div>
      {minutes === 0 && seconds === 0 ? (
        <span>0:00</span>
      ) : (
        <span>
          {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </span>
      )}
    </div>
  )
}
