import { useMenu } from '@/hooks/useMenu'
import React from 'react'

import { Container } from './styles'

import { Breadcrumb } from 'react-bootstrap'
import { useRouter } from 'next/router'

export const SubHeader: React.FC = () => {
  const { active, resetActive } = useMenu()

  const router = useRouter()

  return (
    <Container className="no-print">
      {active && <h2>{active?.title}</h2>}
      {active && (
        <Breadcrumb>
          <Breadcrumb.Item
            as="button"
            onClick={() => {
              router.push('/')
              resetActive()
            }}
          >
            Home
          </Breadcrumb.Item>
          {active.parent && (
            <Breadcrumb.Item as="button">
              {active?.parent?.title}
            </Breadcrumb.Item>
          )}
          <Breadcrumb.Item as="button" active>
            {active?.title}
          </Breadcrumb.Item>
        </Breadcrumb>
      )}
    </Container>
  )
}
