import { createGlobalStyle, keyframes } from 'styled-components'

const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  } 100% {
    transform: scale(2);
    opacity: 0;
  }
`

const loadBg = keyframes`
  0% {
    opacity: 1;
  } 75% {
    opacity: 0.6;
  } 100% {
    opacity: 1;
  }
`

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  all-break-line {
    * {
      line-break: anywhere;
    }
  }

  .gap-4 {
    gap: 0.4rem;
  }

  button:disabled {
    color: #888;
  }
  .text-muted {
    color: #888 !important;
  }
  
  a {
    color: ${props => props.theme.colors.primary};
  }

  body {
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smotthing: antialiased;
    color: ${props => props.theme.colors.text};
  }

  button {
    cursor: pointer;
    background: none;
    border: none;
  }
  
  button, input, select, textarea {
    font-family: 'Open Sans', sans-serif;
  }

  p {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
  }

  small {
    font-family: 'Open Sans', sans-serif;
    font-weight: 200;
  }

  h1,h2,h3,h4,h5,h6,strong,b {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
  }

  html {
    @media (max-width: 1920px) {
      font-size: 88%;
    }
    @media (max-width: 1200px) {
      font-size: 86.75%;
    }
    @media (max-width: 720px) {
      font-size: 80%;
    }
  }

  .w-20 {
    width: 20% !important;
  }

  .form-control {
    border-radius: 4px;
    background: ${props =>
      props.theme.name === 'dark' ? 'rgb(255,255,255,.9)' : '#fff'};
    border-color: ${props =>
      props.theme.name === 'dark' ? 'rgb(255, 255, 255, 0.6)' : ''};
    color: ${props => (props.theme.name === 'dark' ? '#000' : '')};
    option {
      color: #444;
    }
    &::placeholder {
      color: ${props => (props.theme.name === 'dark' ? '#444' : '')};
    }
    &:disabled {
      cursor: not-allowed;
      color: ${props => (props.theme.name === 'dark' ? '#eee' : '')};
      background: ${props =>
        props.theme.name === 'dark'
          ? 'rgb(255,255,255,.1)'
          : 'rgba(0,0,0,.04)'};
    }
  }
  input.form-control {
    &:disabled, &:read-only {
      cursor: not-allowed;
      color: ${props => (props.theme.name === 'dark' ? '#eee' : '')};
      background: ${props =>
        props.theme.name === 'dark'
          ? 'rgb(255,255,255,.1)'
          : 'rgba(0,0,0,.04)'};
    }
  }
  select.form-control {
    &:read-only {
      background: ${props =>
        props.theme.name === 'dark' ? 'rgb(255,255,255,.9)' : '#fff'};
      border-color: ${props =>
        props.theme.name === 'dark' ? 'rgb(255, 255, 255, 0.6)' : ''};
      color: ${props => (props.theme.name === 'dark' ? '#000' : '')};
    }
  }
  .form-control-sm {
    font-size: 0.7rem;
  }
  .form-label {
    font-size: 0.75rem;
    margin-bottom: 0.3rem;
  }
  .btn-sm {
    // height: 24px !important;
  }
  .btn-primary {
    background-color: ${props => props.theme.colors.primary};
    border-color: ${props => props.theme.colors.primary};
    &:hover {
      background-color: ${props => props.theme.colors.primaryHover};
      border-color: ${props => props.theme.colors.primaryHover};
    }
    &:disabled {
      background-color: ${props => props.theme.colors.primary};
      border-color: ${props => props.theme.colors.primary};
      opacity: 0.4;
    }
  }

  .btn-outline-primary {
    border-color: ${props =>
      props.theme.name === 'light'
        ? props.theme.colors.primary
        : props.theme.colors.primaryHover};
    color: ${props =>
      props.theme.name === 'light'
        ? props.theme.colors.primary
        : props.theme.colors.primaryHover};
    &:hover {
      background-color: ${props => props.theme.colors.primaryHover};
      border-color: ${props => props.theme.colors.primaryHover};
      color: #fff;
    }
  }

  .btn-outline-secondary {
    border-color: ${props =>
      props.theme.name === 'light' ? '#69757d' : '#eee'};
    color: ${props => (props.theme.name === 'light' ? '#69757d' : '#eee')};
  }

  .comitte-item {
    display: flex;
    align-items: flex-start;
    position: relative;
    .user-image {
      z-index: 9;
      background: #999;
      color: #eee;
    }
    &.flex-row-reverse {
      .user-name {
        right: 30px;
        left: auto;
      }
    }
    .user-name {
      position: absolute;
      color: #999;
      left: 30px;
      top: 0;
      font-size: 0.7rem;
      padding: 0.2rem 0.2rem 0;
      height: 18px;
      align-items: flex-end;
      display: flex;
      span {
        font-weight: 700;
      }
    }
    .text-box {
      background: #efefef;
      border-radius: 0 1rem 1rem 1rem;
      padding: 0.75rem;
      max-width: 80%;
      margin-top: 25px;
      margin-right: 0rem;
      margin-left: 5px;
    }
    &.flex-row-reverse {
      .text-box {
        margin-right: 5px;
        margin-left: 0rem;
        border-radius: 1rem 0 1rem 1rem;
      }
    }
  }

  table {
    thead {
      tr {
        th {
          border-bottom: none;
          line-height: 0.8rem;
          font-weight: 600;
          color: #3757a1;
          button {
            padding: 0;
            font-size: 0.9rem;
          }
          .auto-opacity {
            display: inline-block;
            opacity: 0;
          }
          &:hover {
            .auto-opacity {
              display: inline-block;
              opacity: 1;
            }
          }
        }
      }
    }
    td, th {
      padding: 8px !important;
      vertical-align: middle !important;
      font-size: 1rem;
      color: ${props => props.theme.colors.text};
      border-color: ${props =>
        props.theme.name === 'light' ? '#c9c9c9' : '#999'};
        button {
          color: ${props => props.theme.colors.text};
        }
    }
    tbody {
      tr {
        cursor: pointer;
        &:nth-child(odd) {
          background: #f9f9f9;
        }
      }
      tr.selected {
        background: ${props =>
          props.theme.name === 'light'
            ? 'rgba(0,0,0,.05)'
            : 'rgba(255,255,255,.2)'};
        td {
          font-weight: 700;
          
        }
      }
    }
  }

  .white-space-no-wrap {
    white-space: nowrap !important;
  }

  .float-name {
    width: 100%;
    border-radius: 20px;
    border-left: 9px solid ${props => props.theme.colors.secondary};
    box-shadow: 0px 0px 5px rgba(0,0,0,.15);
    font-size: 0.53rem;
    padding: 0.25rem;
    position: absolute;
    top: 100%;
    left: 0;
    background: ${props => (props.theme.name === 'light' ? '#eee' : '#444')};
    color: ${props => (props.theme.name === 'light' ? '#222' : '#eee')};
    font-weight: 900;
  }

  .modal-content {
    border-radius: 1rem;
    .modal-header {
      padding: 2rem 1.5rem 1rem 1.5rem;
    }
    .modal-body {
      padding: 1rem 1.5rem 2rem 1.5rem;
    }
    background: ${props => (props.theme.name === 'light' ? '#fff' : '#21435a')};
    button {
      color: ${props => (props.theme.name === 'light' ? '' : '#fff')};
    }
  }

  button {
    svg {
      margin-right: 0.25rem;
      margin-bottom: 0.1rem;
    }
  }

  .signal {
    width: 8px;
    height: 8px;
    display: block;
    border-radius: 50%;
    position: relative;
    &::before {
      width: 8px;
      height: 8px;
      position: absolute;
      content: '';
      top: 0rem;
      left:0rem;
      border-radius: 50%;
      border: 2px solid transparent;
      animation: ${pulse} 1s infinite;
    }
    &.green {
      background: #14efa0;
      &::before {
        border-color: #14efa0;
      }
    }
    &.danger {
      background: red;
      &::before {
        border-color: red;
      }
    }
  }

  .bg-gray {
    background-color: rgba(0,0,0,.05);
  }
  .radius-4 {
    border-radius: 0.25rem;
  }
  .radius-full {
    border-radius: 4rem;
  }
  .border-dashed-2 {
    border: 2px dashed rgba(0,0,0,.1);
  }
  .border-1 {
    border: 1px solid #c0c0c0;
  }

  .hover-scale {
    transition: 300ms;
  }

  .hover-scale:hover {
    transform: scale(1.05);
    filter: brightness(105%);
  }

  .text-main {
    color: ${props => props.theme.colors.primary};
  }

  button.btn-primary svg, a.btn-primary svg {
    margin-top: -2px;
  }

  button:disabled {
    cursor: not-allowed;
  }

  .alert_sucess {
    display: inline;
    padding: 0.2rem 0.4rem 0.3rem;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    text-transform: uppercase;
    background-color: #98ffc8;
    color: #126a37;
  }
  .alert_danger {
    display: inline;
    padding: 0.2rem 0.4rem 0.3rem;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    text-transform: uppercase;
    background-color: #f5abab;
    color: #821616;
  }

  .floating_action {
    position: absolute;
    width: 100px;
    right: 13px;
    top: auto;
    background: #fff;
    button.dropdown-toggle {
      display: block;
      font-size: 0.7rem;
      transform: translateY(-3px);
      margin-left: auto;
      margin-right: auto;
    }
  }

  .cell-loading {
    background-color: rgba(0,0,0,.2) !important;
    animation: ${loadBg} 750ms linear infinite;
    filter: blur(5px);
  }

  .bg-load {
    background-color: rgba(0,0,0,.15);
    animation: ${loadBg} 750ms linear infinite;
    width: 100%;
    border-radius: 4px;
  }

  .no-border {
    border: none !important;
    tr, td {
      border: none !important;
    }
  }
  table.table-bfcdigital table-responsive {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0.4rem;
    th {
      background: #ebeef2;
      border-radius: 0.4rem;
      font-size: 0.8rem;
    }
    td {
      background: #ffffff;
      border-radius: 0.4rem;
      font-size: 0.8rem;
      transition: 150ms;
      padding: 5px 10px !important;
      input, select, text-area {
        border: 1px solid #c9c9c9 !important;
      }
    }
    tr:hover {
      td {
        background: #e4ebf5;
      }
    }
    tr.active {
      td {
        background: ${props => props.theme.colors.secondary};
        color: #fff;
        button {
          color: #fff;
        }
      }
    }
  }

  .c-pointer {
    cursor: pointer !important;
  }
  .c-default {
    cursor: default !important;
  }

  .grow-item {
    width: 100%;
  }

  .cell-green {
    background: #d9ffec;
    color: #45996f;
  }
  .cell-red {
    background: #ffe2e0;
    color: #c22f25;
  }
  .cell-gray {
    background: #eee;
    color: #232323;
  }

  .bg-blues {
    background: #edf3fa;
    color: #323c4a;
  }
  .bg-light-yellow {
    background: #fff0db;
    color: #4a3212;
  }
  .bg-light-green {
    background: #edfff4 !important;
  }

  ul {
    list-style: none;
    padding:0;
    margin:0;
    li {
      padding:0;
      margin:0;
    }
  }

  .b-top-2 {
    border-top: 2px solid #999;
  }
  .b-bottom-2 {
    border-bottom: 2px solid #999;
  }

  .bg-none {
    background: none !important;
  }

  .content_associations {
    max-height: 150px;
    margin-top: 10px;
    background: #eee;
    border-radius: 4px;
    overflow: auto;
    padding: 4px;
  }
  .selected_association {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    width: 100%;
    div {
      button {
        background: rgba(0, 0, 0, 0.1);
        border-radius: 50%;
      }
    }
  }

  div.container-table-action {
    width: 100%;
    .wrapper-table {
      position: relative;
      overflow: auto;
      white-space: nowrap;
      .sticky-col {
        position: -webkit-sticky;
        position: sticky;
        background-color: #fdfdfd;
        text-align: center !important;
      }
      .floating-col {
        width: 100px;
        min-width: 110px;
        max-width: 110px;
        right: 0px;
      }
      tbody {
        tr:nth-child(2n + 1) .sticky-col {
          background: rgb(249, 249, 249);
        }
        tr:hover .sticky-col {
          background: #eaeaea;
        }
      }
    }
  }
  

  @media print {
    .no-print {
      display: none !important;
    }
    .scroll-box-item {
      overflow: unset !important;
    }
  }
  .alert-heading {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }

  .jodit_fullsize {
    z-index: 99999 !important;
    background: #fff;
  }

  .sticky-col .dropdown-menu.show {
    right: 20px !important;
  }

  .status-danger {
    border-left: 3px solid #dc3545;
  } 
  .status-success {
    border-left: 3px solid #28a745
  } 

  .modal-full {
    padding-left: 0 !important;
  }
  .modal-full > div {
    max-width: 95vw !important;
  }
  
  .modal-semifull {
    padding-left: 0 !important;
  }
  .modal-semifull > div {
    max-width: 85vw !important;
  }

  .line-break-anywhere {
    line-break: anywhere;
  }

  .ws-status {
    position: fixed;
    bottom: 0px;
    right: 10px;
    z-index: 11;
    background: #fff;
    border: 1px solid #c9c9c9;
    border-radius: 4px 4px 0px 0px;
    padding: 0.5rem;
    &.online {
      span {
        color: #126a37;
      }
    }
    &.offline {
      span {
        color: #821616;
      }
    }
    .chat {
      position: absolute;
      bottom: 45px;
      border-radius: 6px;
      right: 0;
      background-color: #fff;
      width: 100%;
      box-shadow: 0 0 5px rgba(0,0,0,.2);
      .content {
        min-height: 25vh;
        max-height: 73vh;
        overflow: auto;
        display: flex;
        flex-direction: column-reverse;
      }
      .message {
        border-radius: 12px;
        background-color: #eee;
        font-size: 0.85rem;
        padding: 1rem 1rem 0.5rem 1rem;
        margin-top: 0.5rem;
      }
      .input-box {
        background: #f2f2f2;
        display: flex;
        input {
          margin-right: 5px;
        }
      }
    }
    .online-user {
        font-weight: bold;
        width: 20px;
        height: 20px;
        font-size: 10px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #eee;
        border: 1px solid #c9c9c9;
        position: relative;
        margin-left: -6px;
      }
    .box-online-users {
      display: flex;
      margin-left: 15px;
      width: 250px;
      position: relative;
    }
  }
`
