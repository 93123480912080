import React, { useState, useEffect } from 'react'
import { Container, Box, LogoBox, ContentInput } from './styles'
import { toast } from 'react-toastify'
import { useAuth } from '@/hooks/useAuth'
import { Formik, FormikHelpers } from 'formik'
import Head from 'next/head'
import * as Yup from 'yup'

import { Form, Button, Card } from 'react-bootstrap'

import { useRouter } from 'next/router'
import { api } from '@/hooks/api'

interface FormValues {
  email: string
  password: string
  mfaToken: string
}

interface SendRecoveryValues {
  email: string
}

interface RecoveryValues {
  password: string
}
//

export const AuthPage: React.FC<
  Partial<{
    signedOutBecauseOfInactivity: boolean
  }>
> = ({ signedOutBecauseOfInactivity = false }) => {
  const { signIn, sendRecovery, recovery, signOut, user } = useAuth()
  const [recoveryAccount, setRecoveryAccount] = useState(false)
  const [tokenRecovery, setTokenRecovery] = useState('')

  const [mfaTokenState, setMfaTokenState] = useState(false)

  const {
    query: { t }
  } = useRouter()

  useEffect(() => {
    if (t) {
      setTokenRecovery(t as string)
    } else {
      setTokenRecovery('')
    }
  }, [t])

  if (tokenRecovery !== '') {
    return (
      <Container>
        <Head>
          <title>BFC Digital - Recuperar Conta</title>
        </Head>

        <aside>
          <img src="/logo-branca.png" alt="BFC" style={{ maxWidth: '60px' }} />
          <div>
            <h2>Bem-vindo à BFC!</h2>
            <p>A parceria certa para a sua empresa está aqui!</p>
          </div>
          <small>&copy; {new Date().getFullYear()} BFC</small>
        </aside>
        <section>
          <Formik
            initialValues={{
              password: ''
            }}
            validationSchema={Yup.object({
              password: Yup.string().required('Senha obrigatória')
            })}
            onSubmit={async (
              values: RecoveryValues,
              { setSubmitting }: FormikHelpers<RecoveryValues>
            ) => {
              const { password } = values

              try {
                await recovery(tokenRecovery, password)
              } catch (error) {
                // TODO: Tratar mensagens de erro
                toast.error(error.message)
              } finally {
                setSubmitting(false)
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              isSubmitting,
              errors,
              touched
            }) => (
              <Box onSubmit={handleSubmit}>
                <ContentInput>
                  <small className="text-center">
                    Digite uma nova senha para sua conta
                  </small>
                  <Form.Group className="mb-2">
                    <Form.Label>Senha</Form.Label>
                    <Form.Control
                      name="password"
                      type="password"
                      placeholder="Senha"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      className="input-radius"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password && touched.password && errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    className="d-block mt-3"
                    style={{ width: '100%' }}
                    variant="primary"
                    block
                  >
                    Recuperar Conta
                  </Button>
                </ContentInput>
              </Box>
            )}
          </Formik>
        </section>
      </Container>
    )
  }

  if (recoveryAccount) {
    return (
      <Container>
        <Head>
          <title>BFC Digital - Esqueci minha senha</title>
        </Head>
        <aside>
          <img src="/logo-branca.png" alt="BFC" style={{ maxWidth: '60px' }} />
          <div>
            <h2>Bem-vindo à BFC!</h2>
            <p>A parceria certa para a sua empresa está aqui!</p>
          </div>
          <small>&copy; {new Date().getFullYear()} BFC</small>
        </aside>
        <section>
          <Formik
            initialValues={{
              email: ''
            }}
            validationSchema={Yup.object({
              email: Yup.string()
                .email('Endereço de email inválido')
                .required('Email obrigatório')
            })}
            onSubmit={async (
              values: SendRecoveryValues,
              { setSubmitting }: FormikHelpers<SendRecoveryValues>
            ) => {
              const { email } = values

              try {
                await sendRecovery(email)
              } catch (error) {
                // TODO: Tratar mensagens de erro
                // toast.error(error.message)
              } finally {
                setSubmitting(false)
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              isSubmitting,
              errors,
              touched
            }) => (
              <Box onSubmit={handleSubmit}>
                <ContentInput>
                  <small className="text-center">
                    Digite o e-mail de sua conta
                  </small>
                  <Form.Group className="mb-2">
                    <Form.Label>E-mail</Form.Label>
                    <Form.Control
                      name="email"
                      type="email"
                      placeholder="E-mail"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      className="input-radius"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email && touched.email && errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    className="d-block mt-3"
                    style={{ width: '100%' }}
                    variant="primary"
                    block
                  >
                    Recuperar Conta
                  </Button>
                  <Button
                    type="button"
                    className="mt-2"
                    style={{ width: '100%' }}
                    variant="link"
                    onClick={() => setRecoveryAccount(false)}
                  >
                    Cancelar
                  </Button>
                </ContentInput>
              </Box>
            )}
          </Formik>
        </section>
      </Container>
    )
  }

  if (signedOutBecauseOfInactivity) {
    return (
      <Container>
        <Head>
          <title>BFC Digital - Autenticação</title>
        </Head>
        <aside>
          <img src="/logo-branca.png" alt="BFC" style={{ maxWidth: '60px' }} />
          <div>
            <h2>Bem-vindo à BFC!</h2>
            <p>A parceria certa para a sua empresa está aqui!</p>
          </div>
          <small>&copy; {new Date().getFullYear()} BFC</small>
        </aside>
        <section>
          <Formik
            initialValues={{
              password: ''
            }}
            validationSchema={Yup.object({
              password: Yup.string().required('Senha obrigatória')
            })}
            onSubmit={async (
              values: {
                password: string
              },
              { setSubmitting }: FormikHelpers<FormValues>
            ) => {
              const { password } = values

              try {
                const state = await signIn({
                  email: user.email,
                  password,
                  signedOutBecauseOfInactivity
                })

                if (state === 'mfa_token_required') {
                  setMfaTokenState(true)
                }
              } catch (error) {
                // TODO: Tratar mensagens de erro
                toast.error(error.message)
              } finally {
                setSubmitting(false)
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              isSubmitting,
              errors,
              touched
            }) => (
              <Box onSubmit={handleSubmit}>
                <div>
                  <LogoBox>Tempo de sessão expirado</LogoBox>
                  <ContentInput>
                    <Form.Group className="mb-2">
                      <Form.Label>E-mail</Form.Label>
                      <Card className="p-2">{user.email}</Card>
                    </Form.Group>
                    <Form.Group className="mb-0">
                      <Form.Label>Senha</Form.Label>
                      <Form.Control
                        name="password"
                        type="password"
                        placeholder="*******"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        className="input-radius"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.password && touched.password && errors.password}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      className="d-block mt-3"
                      style={{ width: '100%' }}
                      variant="primary"
                      block
                    >
                      Entrar
                    </Button>
                    <Button
                      type="button"
                      className="mt-2"
                      style={{ width: '100%' }}
                      variant="link"
                      onClick={() => signOut()}
                    >
                      Sair
                    </Button>
                  </ContentInput>
                </div>
              </Box>
            )}
          </Formik>
        </section>
      </Container>
    )
  }

  return (
    <Container>
      <Head>
        <title>BFC Digital - Autenticação</title>
      </Head>
      <aside>
        <img src="/logo-branca.png" alt="BFC" style={{ maxWidth: '60px' }} />
        <div>
          <h2>Bem-vindo à BFC!</h2>
          <p>A parceria certa para a sua empresa está aqui!</p>
        </div>
        <small>&copy; {new Date().getFullYear()} BFC</small>
      </aside>
      <section>
        <Formik
          initialValues={{
            email: '',
            password: '',
            mfaToken: ''
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email('Endereço de email inválido')
              .required('Email obrigatório'),
            password: Yup.string().required('Senha obrigatória'),
            mfaToken: Yup.string().optional()
          })}
          onSubmit={async (
            values: FormValues,
            { setSubmitting }: FormikHelpers<FormValues>
          ) => {
            const { email, password, mfaToken } = values

            try {
              const state = await signIn({
                email,
                password,
                mfaToken,
                signedOutBecauseOfInactivity
              })

              if (state === 'mfa_token_required') {
                setMfaTokenState(true)
              }
            } catch (error) {
              // TODO: Tratar mensagens de erro
              toast.error(error.message)
            } finally {
              setSubmitting(false)
            }
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            isSubmitting,
            errors,
            touched
          }) => (
            <Box onSubmit={handleSubmit}>
              <div>
                <LogoBox>Entrar</LogoBox>
                <ContentInput>
                  {mfaTokenState ? (
                    <>
                      <Form.Group className="mb-2">
                        <Form.Label>
                          <div className="mr-1">
                            Entre abaixo com o token de 8 dígitos.
                          </div>
                        </Form.Label>
                        <Form.Control
                          name="mfaToken"
                          type="text"
                          placeholder="Token"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.mfaToken}
                          className=""
                        />
                        <Form.Text className="text-muted text-left mt-1">
                          Tokens possuem tempo limite para uso.
                        </Form.Text>
                      </Form.Group>
                      <Button
                        disabled={isSubmitting}
                        type="button"
                        className="d-block mt-3"
                        style={{ width: '100%' }}
                        variant="outline-primary"
                        block
                        onClick={async () => {
                          await api.post('/sessions-send-mfa-token', {
                            email: values.email,
                            password: values.password
                          })

                          toast.success(`Token enviado para ${values.email}`)
                        }}
                      >
                        Enviar token por e-mail
                      </Button>
                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        className="d-block mt-3"
                        style={{ width: '100%' }}
                        variant="primary"
                        block
                      >
                        Entrar
                      </Button>
                      <Button
                        type="button"
                        className="mt-2"
                        style={{ width: '100%' }}
                        variant="link"
                        onClick={() => {
                          setMfaTokenState(false)
                          // reset formik values
                          values.mfaToken = ''
                          values.email = ''
                          values.password = ''
                        }}
                      >
                        Voltar
                      </Button>
                    </>
                  ) : (
                    <>
                      <Form.Group className="mb-2">
                        <Form.Label>E-mail</Form.Label>
                        <Form.Control
                          name="email"
                          type="email"
                          disabled={signedOutBecauseOfInactivity}
                          placeholder="E-mail"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          className="input-radius"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.email && touched.email && errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mb-0">
                        <Form.Label>Senha</Form.Label>
                        <Form.Control
                          name="password"
                          type="password"
                          placeholder="*******"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          className="input-radius"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.password &&
                            touched.password &&
                            errors.password}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        className="d-block mt-3"
                        style={{ width: '100%' }}
                        variant="primary"
                        block
                      >
                        Entrar
                      </Button>

                      <Button
                        type="button"
                        className="mt-2"
                        style={{ width: '100%' }}
                        variant="link"
                        onClick={() => setRecoveryAccount(true)}
                      >
                        Esqueci minha senha
                      </Button>
                    </>
                  )}
                </ContentInput>
              </div>
            </Box>
          )}
        </Formik>
      </section>
    </Container>
  )
}
