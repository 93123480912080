import { EmailSender } from '@/components/pages/SendMail/Sender'
import { TransferBetweenAccounts } from '@/components/pages/Transfer/BetweenAccounts'
import { api } from '@/hooks/api'
import { useAuth } from '@/hooks/useAuth'
import { formatDate, normalizeCnpj } from '@/utils/normalize'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import {
  Badge,
  Dropdown,
  OverlayTrigger,
  Spinner,
  Tooltip
} from 'react-bootstrap'
import { AiOutlineLogout } from 'react-icons/ai'
import { HiOutlineTemplate } from 'react-icons/hi'
import { BiMailSend, BiTransferAlt } from 'react-icons/bi'
import { FaCheckCircle } from 'react-icons/fa'
import { IoMdNotifications, IoMdPerson } from 'react-icons/io'
import { MdQueryBuilder, MdVpnKey } from 'react-icons/md'
import { ModalUpdatePassword } from '../ModalUpdatePassword'

import { Container } from './styles'
import { HasPermission } from '../HasPermission'
import Timer from '../Timer'
import { toast } from 'react-toastify'

export const Header: React.FC = () => {
  const router = useRouter()
  const { user, signOut, signOutBecauseOfInactivity } = useAuth()

  const [transfer, setTransfer] = useState(false)

  const [loadingNotification, setLoadingNotification] = useState(false)
  const [zeroNotification, setZeroNotification] = useState(false)
  const [sendMail, setSendMail] = useState(false)
  const [updatePassword, setUpdatePassword] = useState(false)
  const [notifications, setNotifications] = useState<Notification[]>([])
  const [notificationPage, setNotificationPage] = useState(1)

  const [subscriberParametersData, setSubscriberParametersData] =
    useState<Subscriber | null>(null)

  const [resetTimer, setResetTimer] = useState(false)

  async function handleReaded(id: number) {
    try {
      setNotifications(
        notifications.map(n => {
          if (n.id === id) {
            return {
              ...n,
              readed: true
            }
          }
          return n
        })
      )
      await api.put(`/notifications/${id}`)
    } catch (err) {
      setNotifications(
        notifications.map(n => {
          if (n.id === id) {
            return {
              ...n,
              readed: false
            }
          }
          return n
        })
      )
    }
  }

  useEffect(() => {
    async function loadNotifications() {
      setLoadingNotification(true)
      try {
        const { data } = await api.get('/notifications', {
          params: {
            page: notificationPage
          }
        })
        setNotifications([...notifications, ...data.data])
        if (data.meta.last_page === notificationPage) {
          setZeroNotification(true)
        }
      } catch (err) {
        //
      }
      setLoadingNotification(false)
    }
    loadNotifications()
  }, [notificationPage])

  useEffect(() => {
    const interceptor = api.interceptors.request.use(config => {
      setResetTimer(r => !r)
      return config
    })

    return () => {
      api.interceptors.request.eject(interceptor)
    }
  }, [])

  useEffect(() => {
    if (!user?.subscriber) return
    loadSubscriber()
  }, [user])

  async function loadSubscriber() {
    try {
      const { data } = await api.get(`subscribers/${user.subscriber.id}`)
      setSubscriberParametersData(data)
    } catch (error) {
      console.log(error)
    }
  }

  async function handleLogout() {
    signOut()
  }

  async function handleTimeChange(time: number) {
    if (time === 0) {
      toast.error('Sessão expirada')
      // await handleLogout()
      await signOutBecauseOfInactivity()
    }
  }

  let homologEnviroment = false

  if (window.location.href?.indexOf('app2mig') === -1) {
    homologEnviroment = true
  }

  return (
    <Container
      className="container-fluid no-print"
      style={{ borderBottom: homologEnviroment ? '4px solid orange' : '' }}
    >
      {transfer && (
        <TransferBetweenAccounts onHide={() => setTransfer(false)} />
      )}
      {sendMail && <EmailSender onHide={() => setSendMail(false)} />}
      {updatePassword && (
        <ModalUpdatePassword
          onHide={() => {
            setUpdatePassword(false)
          }}
        />
      )}
      <div className="d-flex justify-content-between align-items-center flex-wrap cont">
        <div className="d-flex align-items-center">
          <button onClick={() => router.push('/')} className="text-white">
            <img src="/logo-branca.png" alt="BFC" />
          </button>

          <div className="d-flex flex-column align-items-start ml-3">
            <strong
              className="text-white radius-4 mb-0 pt-1 pb-1 pl-2 pr-2 company_logged"
              style={{
                fontSize: '0.7rem',
                background: 'rgba(255,255,255,.2)'
              }}
            >
              {user?.subscriber?.name} - {normalizeCnpj(user?.subscriber?.cnpj)}
            </strong>

            <div>
              {homologEnviroment ? (
                <Badge variant="warning" className="mt-1">
                  Ambiente de Homologação
                </Badge>
              ) : null}
            </div>
          </div>
        </div>

        <div className="widgets">
          {subscriberParametersData?.use_idle_session_timeout && (
            <Badge
              variant="secondary"
              className="mt-1 mr-2"
              style={{
                background: 'rgba(255,255,255,.2)'
              }}
            >
              <Timer
                minutes={subscriberParametersData?.idle_session_timeout_minutes}
                reset={resetTimer}
                onTimeChange={handleTimeChange}
              />
            </Badge>
          )}
          <HasPermission permission="Ver Serviço de Envio de E-mail (Root)">
            <button
              type="button"
              className="btn btn-primary bg-none p-0"
              onClick={() => setSendMail(true)}
            >
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="tip-send-mail">Enviar E-mail</Tooltip>}
              >
                <BiMailSend className="m-0" />
              </OverlayTrigger>
            </button>
          </HasPermission>
          <HasPermission permission="Ver Contas Corrente (Root)">
            <button
              type="button"
              className="btn btn-primary bg-none p-0 ml-3"
              onClick={() => setTransfer(true)}
            >
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="tip-transfer">
                    Transferência entre contas
                  </Tooltip>
                }
              >
                <BiTransferAlt className="m-0" />
              </OverlayTrigger>
            </button>
          </HasPermission>

          <Dropdown className="ml-3">
            <Dropdown.Toggle className="p-0 bg-none icon-notification">
              <IoMdNotifications className="m-0" />
              {notifications.filter(n => !n.readed).length > 0 && (
                <Badge pill variant="warning" className="count">
                  {notifications.filter(n => !n.readed).length}
                </Badge>
              )}
            </Dropdown.Toggle>
            <Dropdown.Menu
              className="p-0 notifications-content"
              style={{ minWidth: '20vw', maxHeight: '70vh', overflow: 'auto' }}
            >
              <div className="notifications-box">
                {notifications.length === 0 && (
                  <div className="p-4">Nenhuma notificação</div>
                )}
                {notifications.map(notification => (
                  <div
                    className={`d-flex justify-content-between p-2 ${
                      notification.readed ? '' : 'bg-blues'
                    } align-items-start`}
                    key={notification.id}
                  >
                    <div className="d-flex flex-column w-100">
                      <strong>{notification.description}</strong>
                      <small>{notification.title}</small>
                      <time>
                        <MdQueryBuilder />{' '}
                        {formatDate(notification.created_at, true)}
                      </time>
                    </div>
                    {!notification.readed && (
                      <button
                        type="button"
                        onClick={() => handleReaded(notification.id)}
                      >
                        <FaCheckCircle
                          className="m-0 text-primary"
                          size="1rem"
                        />
                      </button>
                    )}
                  </div>
                ))}
                {!zeroNotification && notifications.length > 0 && (
                  <div className="p-2">
                    <button
                      type="button"
                      onClick={() => setNotificationPage(notificationPage + 1)}
                      disabled={loadingNotification}
                    >
                      {loadingNotification ? (
                        <Spinner animation="border" />
                      ) : (
                        'Carregar Mais'
                      )}
                    </button>
                  </div>
                )}
              </div>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown className="ml-3">
            <Dropdown.Toggle className="p-0 bg-none">
              <IoMdPerson className="m-0" />
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ minWidth: '180px' }}>
              <div className="pl-3 pr-3 pt-1 pb-1">
                <span className="d-block">
                  Olá, <strong>{user.name}</strong>!
                </span>
                <small>{user.email}</small>
              </div>
              <Dropdown.Divider />
              <Dropdown.Item
                as="button"
                className="pl-3 pr-3"
                onClick={() => router.push('/committee-templates')}
              >
                <HiOutlineTemplate size="1rem" /> Template de comitê
              </Dropdown.Item>
              <Dropdown.Divider />

              <Dropdown.Item
                as="button"
                className="pl-3 pr-3"
                onClick={() => setUpdatePassword(true)}
              >
                <MdVpnKey size="1rem" /> Trocar senha
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                className="text-danger pl-3 pr-3"
                onClick={handleLogout}
              >
                <AiOutlineLogout className="m-0" size="1rem" /> Sair
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </Container>
  )
}
