import light from '@/styles/light'
import dark from '@/styles/dark'
import React from 'react'
import { ThemeProvider } from 'styled-components'
import { useTheme } from './useTheme'
import { AuthProvider } from './useAuth'
import { ToastContainer } from 'react-toastify'
import { OperationProvider } from './useOperation'
import { BillingProvider } from './useBilling'
import { ModalProvider } from './useModal'
import { WebsocketProvider } from './useWs'
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient()

export const RootProvider: React.FC = ({ children }) => {
  const { theme } = useTheme()
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme === 'light' ? light : dark}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <AuthProvider>
          <ModalProvider>
            <BillingProvider>
              <OperationProvider>
                <WebsocketProvider>{children}</WebsocketProvider>
              </OperationProvider>
            </BillingProvider>
          </ModalProvider>
        </AuthProvider>
      </ThemeProvider>
    </QueryClientProvider>
  )
}
