import { useMenu } from '@/hooks/useMenu'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { useEffect, useRef, useState } from 'react'
import { FaTimes } from 'react-icons/fa'

import { Dropdown } from './dropdown'
import { Container, ScrollMenu } from './styles'

const Menu: React.FC = () => {
  const { active, onChangeActive, config, open, setOpen } = useMenu()

  const [itemOpen, setItemOpen] = useState(null as null | number)

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (!open) {
            setItemOpen(null)
          }
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref, open])
  }

  useEffect(() => {
    if (
      localStorage.getItem('@BFCDigital:menu') === 'open' ||
      !localStorage.getItem('@BFCDigital:menu')
    ) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [])

  function handleMenu(open) {
    open
      ? localStorage.setItem('@BFCDigital:menu', 'close')
      : localStorage.setItem('@BFCDigital:menu', 'open')
    setOpen(!open)
  }

  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)

  const router = useRouter()

  return (
    <Container
      open={open}
      ref={wrapperRef}
      className={`no-print ${open ? 'menu-open' : 'menu-close'}`}
    >
      <button type="button" className="burger" onClick={() => handleMenu(open)}>
        <strong className="desc">MENU</strong>
        {open ? (
          <FaTimes />
        ) : (
          <div className="bars">
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </div>
        )}
      </button>
      <ScrollMenu className="scroll_menu">
        {active === null && (
          <Head>
            <title>BFC Digital</title>
          </Head>
        )}
        {active !== null && (
          <Head>
            <title>BFC Digital - {active?.title}</title>
          </Head>
        )}
        {config?.map((route, index) => {
          if (route.isDropdown) {
            return (
              <Dropdown
                key={index}
                route={route}
                open={itemOpen === index}
                setOpen={() => {
                  if (itemOpen === index) {
                    setItemOpen(null)
                  } else {
                    setItemOpen(index)
                  }
                }}
              />
            )
          }
          return (
            <a
              href={route.route}
              onClick={e => {
                e.preventDefault()
                onChangeActive(route.route)
                router.push(route.route)
              }}
              key={String(index)}
              className={`${
                active && active.route === route.route ? 'active' : ''
              }`}
            >
              {route.icon}
              <strong className="desc">{route.title}</strong>
            </a>
          )
        })}

        {/* <Dropdown isCurrent={current === 2} onClick={() => setCurrent(2)} /> */}
      </ScrollMenu>
    </Container>
  )
}

export default Menu
