import { api } from '@/hooks/api'
import React, { useMemo, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { FaCheck, FaTimes } from 'react-icons/fa'
import { MdWarning } from 'react-icons/md'
import { toast } from 'react-toastify'

// import { Container } from './styles';

export const ModalUpdatePassword: React.FC<{ onHide: () => void }> = ({
  onHide
}) => {
  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState({
    old_password: '',
    new_password: '',
    new_password_confirm: ''
  })

  const hasDiff = useMemo(() => {
    if (!values.new_password && !values.new_password_confirm) {
      return false
    }
    if (!values.new_password || !values.new_password_confirm) {
      return true
    }
    if (values.new_password !== values.new_password_confirm) {
      return true
    }
    return false
  }, [values.new_password, values.new_password_confirm])

  async function handleSubmit() {
    setLoading(true)
    try {
      await api.put('users/me/password', {
        ...values
      })
      toast.success('Senha atualizada')
      onHide()
    } catch (err) {
      //
    }
    setLoading(false)
  }

  return (
    <Modal
      show
      onHide={() => {
        onHide()
      }}
      centered
    >
      <Modal.Header>Trocar senha</Modal.Header>
      <Modal.Body>
        <Form
          onSubmit={e => {
            e.preventDefault()
            handleSubmit()
          }}
        >
          <Form.Group className="mb-2">
            <Form.Label>Senha Antiga</Form.Label>
            <Form.Control
              type="password"
              value={values.old_password}
              onChange={e =>
                setValues({ ...values, old_password: e.target.value })
              }
              disabled={loading}
              required
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>Nova Senha</Form.Label>
            <Form.Control
              type="password"
              value={values.new_password}
              onChange={e =>
                setValues({ ...values, new_password: e.target.value })
              }
              disabled={loading}
              required
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>Repetir nova senha</Form.Label>
            <Form.Control
              type="password"
              value={values.new_password_confirm}
              onChange={e =>
                setValues({ ...values, new_password_confirm: e.target.value })
              }
              disabled={loading}
              required
            />
          </Form.Group>
          {hasDiff ? (
            <div className="alert alert-danger mt-3">
              <MdWarning className="mr-2" />
              Senhas estão divergentes
            </div>
          ) : null}
          <div className="d-flex justify-content-center mt-4">
            <Button type="submit" disabled={hasDiff || loading}>
              <FaCheck /> Salvar
            </Button>
            <Button
              type="button"
              variant="outline-secondary"
              disabled={loading}
              onClick={onHide}
              className="ml-2"
            >
              <FaTimes /> Cancelar
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
