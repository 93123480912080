import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import { FaTimesCircle } from 'react-icons/fa'

import { Container, Results } from './styles'

export const Autocomplete: React.FC<{
  onSelect: (item: any) => void
  columnTitle: string
  columnValue: string
  placeholder?: string
  initialValue?: any
  loadResults: (input: string) => Promise<any[]>
  valueSelectable?: boolean
  disabled?: boolean
  size?: 'sm'
  className?: string
  searchButton?: boolean
}> = ({
  onSelect,
  columnTitle,
  columnValue,
  loadResults,
  placeholder = '',
  valueSelectable = true,
  disabled = false,
  size,
  initialValue = null,
  className = '',
  searchButton = false
}) => {
  const [valueDigited, setValueDigited] = useState('')
  const [zeroResults, setZeroResults] = useState(false)
  const [selected, setSelected] = useState(() => {
    return initialValue
  })

  const input = useRef<any>(null)

  useEffect(() => {
    setSelected(initialValue)
  }, [initialValue])

  const [results, setResults] = useState([] as any[])

  const [loading, setLoading] = useState(false)

  async function handle() {
    setLoading(true)
    try {
      const res = await loadResults(valueDigited)
      setResults(res)
      if (res.length === 0) {
        setZeroResults(true)
      }
    } catch (err) {
      //
    }
    setLoading(false)
  }

  useEffect(() => {
    if (valueDigited.length > 0 && !searchButton) {
      handle()
    } else {
      setResults([])
      setZeroResults(false)
    }
  }, [valueDigited])

  // aqui
  const handleSelect = (value: any) => {
    if (valueSelectable) {
      setSelected(value)
    }
    onSelect(value)
    setResults([])
    setZeroResults(false)
    setValueDigited('')
  }

  useEffect(() => {
    if (!selected) {
      let timeout = null
      const d = input.current?.addEventListener('keyup', e => {
        if (e.target.value.length > 0) {
          clearTimeout(timeout)
          timeout = setTimeout(function () {
            setValueDigited(e.target.value)
          }, 500)
        } else {
          setResults([])
        }
      })
      return d
    }
  }, [input, selected])

  if (selected && selected[columnValue]) {
    return (
      <Container className={className}>
        <Form.Control
          value={selected[columnTitle]}
          size={size}
          className={className}
        />
        <button
          type="button"
          onClick={() => handleSelect('')}
          className={'ml-2 float-icon ' + (size || '')}
          disabled={disabled}
        >
          <FaTimesCircle className="m-0 text-muted" />
        </button>
      </Container>
    )
  }

  return (
    <div
      style={{ position: 'relative', flexDirection: 'row' }}
      className={className}
    >
      <Container
        onSubmit={e => {
          e.preventDefault()
          if (results.length > 0) {
            handleSelect(results[0])
          }
        }}
        className={className}
      >
        <Row>
          <Col sm={searchButton ? 10 : 12}>
            <Form.Control
              placeholder={placeholder}
              ref={input}
              onFocus={() => {
                if (valueDigited.length > 0 && !searchButton) {
                  handle()
                }
              }}
              onBlur={() =>
                setTimeout(() => {
                  setResults([])
                  setZeroResults(false)
                }, 500)
              }
              className={className}
              disabled={disabled}
              size={size}
            />
            {loading && (
              <Spinner animation="border" className="ml-2 float-icon" />
            )}
          </Col>
          {searchButton && (
            <Col>
              <Button disabled={disabled} onClick={handle}>
                Buscar
              </Button>
            </Col>
          )}
        </Row>
      </Container>
      {zeroResults && !loading && (
        <Results>
          <div className="pl-3 pr-3 pt-2 pb-2 text-muted">
            Nenhum resultado encontrado
          </div>
        </Results>
      )}
      {results?.length > 0 && (
        <Results>
          {results?.map(item => (
            <button
              key={item[columnValue]}
              type="button"
              onClick={() => handleSelect(item)}
            >
              {item[columnTitle]}
            </button>
          ))}
        </Results>
      )}
    </div>
  )
}
