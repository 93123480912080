import { socket } from '@/services/socket'
import { createContext, useContext, useEffect, useState } from 'react'
import { useAuth } from './useAuth'
import { useRouter } from 'next/router'

interface OperationInSync {
  userName: string
  operationId: number
  operationCode: number
}

interface WebsocketContextHook {
  isConnected: boolean
  operationsInSync: OperationInSync[]
}

const WebsocketConsumer = createContext<WebsocketContextHook>(
  {} as WebsocketContextHook
)

export const WebsocketProvider: React.FC = ({ children }) => {
  const [isConnected, setIsConnected] = useState(socket.connected)

  const [operationsInSync, setOperationsInSync] = useState<OperationInSync[]>(
    []
  )

  const { token } = useAuth()

  const router = useRouter()

  useEffect(() => {
    function onConnect() {
      setIsConnected(true)
    }

    function onDisconnect() {
      setIsConnected(false)
    }

    function onOperationLoading({
      operationId,
      operationCode,
      userName,
      status
    }: {
      operationCode: number
      operationId: number
      status: boolean
      userName: string
    }) {
      setOperationsInSync(prev =>
        status
          ? [
              ...prev,
              {
                operationId: operationId,
                operationCode: operationCode,
                userName: userName || '-'
              }
            ]
          : [...prev.filter(o => o.operationId !== operationId)]
      )
    }

    socket.on('connect', onConnect)
    socket.on('disconnect', onDisconnect)
    socket.on('operation-loading', onOperationLoading)

    return () => {
      socket.off('connect', onConnect)
      socket.off('disconnect', onDisconnect)
      socket.off('operation-loading', onOperationLoading)
    }
  }, [])

  useEffect(() => {
    if (token && router.pathname === '/operations/register') {
      socket.auth = {
        token
      }
      socket.connect()
    } else {
      socket.disconnect()
    }
  }, [token, router])

  return (
    <WebsocketConsumer.Provider
      value={{
        isConnected,
        operationsInSync
      }}
    >
      {children}
    </WebsocketConsumer.Provider>
  )
}

export const useWs = (): WebsocketContextHook => {
  const context = useContext(WebsocketConsumer)

  if (!context) {
    throw new Error('The hook useWs must be used within an WebsocketProvider')
  }

  return context
}
