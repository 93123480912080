import styled from 'styled-components'

// import img from '@/assets/images/bg-auth.jpg'

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: row;
  aside {
    background-image: url(https://app.grupobfc.com.br/media/bg/bg-4.jpg);
    background-repeat: no-repeat;
    background-color: #444;
    background-position: 7% 50%;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 40px;
    width: 605px;
    height: 100vh;
    h2 {
      color: #fff;
      font-size: 2.5rem;
    }
    p {
      color: #fff;
      font-size: 1.5rem;
      font-weight: 200;
    }
    small {
      color: #fff;
      font-weight: 200;
    }
  }
  section {
    width: calc(100% - 605px);
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .form-label {
    font-size: 0.75rem;
    margin-bottom: 0.3rem;
  }
  .form-control {
    border-radius: 4px;
    font-family: 'Open Sans', sans-serif;
    background: rgb(255, 255, 255);
  }
  .btn-primary {
    background-color: ${props => props.theme.colors.primary};
    border-color: ${props => props.theme.colors.primary};
    &:hover {
      background-color: ${props => props.theme.colors.primaryHover};
      border-color: ${props => props.theme.colors.primaryHover};
    }
    &:disabled {
      background-color: ${props => props.theme.colors.primary};
      border-color: ${props => props.theme.colors.primary};
      opacity: 0.4;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    aside {
      background-position: 50% 50%;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;
      height: auto;
      padding: 1.5rem;
      h2 {
        font-size: 1.5rem;
      }
      p {
        font-size: 1rem;
      }
    }
    section {
      width: 100%;
      padding: 1.5rem;
    }
  }
`

export const Box = styled.form`
  width: 100%;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
  background: ${props =>
    props.theme.name === 'light' ? '#fff' : 'rgba(0,0,0,.3)'};
  border-radius: 4px;
  color: ${props =>
    props.theme.name === 'light' ? props.theme.colors.primary : '#fff'};
  input + input {
    margin-top: 10px;
  }
  > div {
    width: 100%;
    display: block;
  }
  small {
    font-size: 10px;
    display: block;
    text-align: center;
    margin-top: 1rem;
  }
  @media screen and (max-width: 600px) {
    flex: 1;
    max-width: 100%;
  }
  .form-control {
    background: ${props =>
      props.theme.name === 'light' ? '' : 'rgba(255,255,255,.2)'};
    color: ${props => (props.theme.name === 'light' ? '' : '#fff')};
    &::placeholder {
      color: ${props => (props.theme.name === 'light' ? '' : '#f9f9f9')};
    }
  }
`

export const LogoBox = styled.header`
  width: 100%;
  text-align: center;
  color: #3757a1;
  font-size: 1.8rem;
  span {
    width: 100px;
    height: 50px;
    background: #fff;
    display: block;
    margin: auto;
  }
  h2 {
    font-weight: 700;
    font-size: 18px;
    margin-top: 0.75rem;
    color: #fff;
  }
  p {
    color: #d1d1d1;
    line-height: 1.6;
    font-size: 0.8125rem;
    font-weight: 400;
  }
`

export const ContentInput = styled.div`
  padding: 20px;
`
