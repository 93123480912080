import styled, { css } from 'styled-components'

interface IContainer {
  open: boolean
}

export const Container = styled.aside`
  background: #fdfdfd;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  color: ${props => (props.theme.name === 'light' ? '#79858c' : '#eee')};
  transition: 300ms;
  width: ${(props: IContainer) => (props.open ? '260px' : '60px')};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  z-index: 999;
  top: 0;
  left: 0;
  height: 100%;
  a {
    text-decoration: none;
  }
  button.burger {
    background: #ededed;
    width: 100%;
    height: 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.95rem;
    padding-left: 1.2rem;
    strong {
      font-size: 0.9rem;
      color: ${props => (props.theme.name === 'light' ? '#79858c' : '#eee')};
      font-weight: 500;
      display: ${(props: IContainer) => (props.open ? 'block' : 'none')};
    }
    color: #79858c;
    div.bars {
      width: 100%;
      max-width: 1.2rem;
      span.bar {
        display: block;
        width: 100%;
        height: 2px;
        background: ${props =>
          props.theme.name === 'light' ? '#79858c' : '#eee'};
      }
      span.bar + span.bar {
        margin-top: 3px;
      }
    }
  }
  ${(props: IContainer) =>
    props.open &&
    css`
      .dropdown-menu {
        margin-top: 0.4rem;
        width: 100%;
      }
    `}
  ${(props: IContainer) =>
    !props.open &&
    css`
      position: relative;
      .arrow {
        display: none !important;
      }
      .icone {
        margin: 0px !important;
        width: 1.75rem !important;
        height: 1.75rem !important;
      }
      .dropdown-options {
        display: none;
      }
      > div {
        padding: 0px;
        &.scroll_menu {
          overflow-y: unset;
          @media screen and (max-width: 650px) {
            transform: translateX(-70vw);
          }
          > div.drop_container {
            div {
              position: relative;
              button,
              a {
                position: relative;
                left: 0px;
                z-index: 999999999;
                border-radius: 0 0.4rem 0.4rem 0 !important;
                background: #fff;
                color: ${props => props.theme.colors.primary};
                width: 180px;
                padding: 0.5rem 1rem;
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
                border-left: 2px solid ${props => props.theme.colors.primary};
                margin: 1px !important;
                &:hover {
                  background: ${props => props.theme.colors.primary};
                  color: #fff;
                }
              }
            }
          }
          button,
          a {
            border-radius: 0px !important;
            position: relative;
            .desc {
              display: none;
              position: absolute;
              left: calc(100% + 12px);
              top: 0.75rem;
              border-radius: 0.45rem;
              box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
              z-index: 1000000000000;
              background: ${props =>
                props.theme.name === 'light'
                  ? props.theme.colors.primary
                  : '#fff'};
              color: ${props =>
                props.theme.name === 'light'
                  ? '#fff'
                  : props.theme.colors.primary} !important;
              padding: 0.25rem 0.75rem;
              font-weight: bold;
            }
            &:hover {
              .desc {
                display: block;
              }
            }
          }
        }
      }
    `}
  @media screen and (max-width: 650px) {
    position: unset;
    width: 0;
    button.burger {
      position: fixed;
      bottom: 3vh;
      right: 5vw;
      width: 15vw;
      height: 15vw;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
      background-color: ${props => props.theme.colors.primary};
      color: #fff;
      .desc {
        display: none;
      }
      div.bars {
        margin: 0;
        max-width: 100%;
        span.bar {
          height: 3px;
          width: 100%;
          background-color: #fff;
        }
        span.bar + span.bar {
          margin-top: 6px;
        }
      }
    }
    .scroll_menu {
      position: fixed;
      top: 0;
      left: 0;
    }
  }
`
export const ScrollMenu = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  overflow-y: auto;
  @media screen and (max-width: 650px) {
    transform: translateX(0);
    transition: 400ms;
    max-width: 60vw;
    height: 100vh;
    background: #f0f0f0;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    left: 0;
  }
  > div.drop_container {
    display: flex;
    flex-direction: column;
    > button,
    > a {
      margin: 3px 5px !important;
    }
    div {
      display: flex;
      flex-direction: column;
      padding: 0.5rem;
      background: #f7f6f6;
      margin-top: 0;
      > button,
      > a {
        margin: 2px 0 !important;
        border-radius: 0.4rem;
        padding: 0.5rem 1rem;
        font-size: 0.8rem;
        color: ${props => (props.theme.name === 'light' ? '#79858c' : '#eee')};
        &:hover {
          color: #fff;
        }
      }
    }
  }
  button,
  a {
    margin: 3px 5px 0 !important;
    border-radius: 4px;
    padding: 0.98rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: 300ms;
    svg {
      margin-right: 6px;
      transition: 300ms;
      color: ${props => (props.theme.name === 'light' ? '#79858c' : '#eee')};
    }
    strong {
      font-size: 0.9rem;
      color: ${props => (props.theme.name === 'light' ? '#79858c' : '#eee')};
      font-weight: 400;
    }
    .arrow {
      margin-left: auto;
    }
    &:hover,
    &.active {
      background: ${props =>
        props.theme.name === 'light'
          ? props.theme.colors.primary
          : 'rgb(9, 32, 63, 0.75)'};
      strong,
      svg {
        color: #fff;
      }
      .icone {
        background: #fff;
      }
    }
  }
  button + button {
    margin-top: 6px;
  }
  div + div {
    margin-top: 3px;
  }
  div.dropdown-options {
    padding: 8px 25px;
    button {
      position: relative;
      border-radius: 0px;
      padding: 0.7rem;
      margin: 0;
      &.active {
        background: none;
        strong {
          color: ${props => props.theme.colors.primary};
        }
        &::before {
          background: ${props => props.theme.colors.primary};
          width: 3px;
          left: -1px;
        }
      }
    }
  }
`
