import { Theme } from './styles'

const theme: Theme = {
  name: 'light',
  colors: {
    background: '#fff',
    text: '#444',
    primary: '#3757a1',
    primaryHover: '#234186',
    secondary: '#00a6d2',
    danger: '#e04848'
  }
}

export default theme
