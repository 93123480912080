import { useCallback } from 'react'
import { PermissionsList, PermissionsEnum } from '@/@types/permission_list'
import { useAuth } from '@/hooks/useAuth'

type Chil = (can: boolean) => any

export function HasPermission({
  permission,
  children,
  company_id
}: {
  permission: PermissionsList
  children: JSX.Element | Chil
  company_id?: number
}): JSX.Element | any {
  const { permissions } = useAuth()

  const permissionItem = PermissionsEnum[permission]

  const verify = useCallback(() => {
    if (
      permissions.find(
        p =>
          p.slug === permissionItem &&
          (company_id ? p.company_id === company_id : true)
      )
    ) {
      return true
    }
    return false
  }, [permissions, permissionItem])

  if (typeof children === 'function') {
    return children(verify())
  }
  if (verify()) {
    return children
  }
  return null
}
