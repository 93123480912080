import { socket } from '@/services/socket'
import { normalizeDecimal } from '@/utils/normalize'
import { useRouter } from 'next/router'
import { createContext, useState, useContext, useRef } from 'react'
import { toast } from 'react-toastify'
import { api } from './api'

interface IDetail {
  averageTerm: number
  revenue: number
  revenueAdValorem: number
  smallerTitle: number
  biggerTitle: number
  net: number
  totalTitlesRejected: number
  qtdTitlesRejected: number
  loading: boolean
  averageTermWithoutDayPlus: number
  revenueWithoutDayPlus: number
  revenueAdValoremWithoutDayPlus: number
  netWithoutDayPlus: number
  qtdUnnotified: number
  valueUnnotified: number
}
interface OperationContext {
  operation: Operation
  operationDetail: IDetail
  setOperation: (operation: Operation) => void
  selectOperation: (item: Operation) => void
  syncOperation: () => Promise<void>
  refreshOperation: (calc?: boolean, operation_id?: number) => Promise<void>
  loading: boolean
  confirmDelete: boolean
  additiveOpen: boolean
  sendOperationToAnalysis: boolean
  confirmSignatures: boolean
  confirmPayment: boolean
  setConfirmDelete: (i: boolean) => void
  setAdditiveOpen: (i: boolean) => void
  setSendOperationToAnalysis: (i: boolean) => void
  setConfirmSignatures: (i: boolean) => void
  setConfirmPayment: (i: boolean) => void
}

const OperationC = createContext<OperationContext>({} as OperationContext)

export const OperationProvider: React.FC = ({ children }) => {
  const [operation, setOperation] = useState<Operation>({} as Operation)
  const [loading, setLoading] = useState(false)

  const [confirmDelete, setConfirmDelete] = useState(false)
  const [additiveOpen, setAdditiveOpen] = useState(false)
  const [sendOperationToAnalysis, setSendOperationToAnalysis] = useState(false)
  const [confirmSignatures, setConfirmSignatures] = useState(false)
  const [confirmPayment, setConfirmPayment] = useState(false)

  const [operationDetail, setOperationDetail] = useState<IDetail>({
    averageTerm: 0,
    revenue: 0,
    revenueAdValorem: 0,
    smallerTitle: 0,
    biggerTitle: 0,
    net: 0,
    totalTitlesRejected: 0,
    qtdTitlesRejected: 0,
    loading: true,
    averageTermWithoutDayPlus: 0,
    revenueWithoutDayPlus: 0,
    revenueAdValoremWithoutDayPlus: 0,
    netWithoutDayPlus: 0,
    qtdUnnotified: 0,
    valueUnnotified: 0
  })

  const customId = 'operation-sync'
  const toastId = useRef(null)

  function selectOperation(item: Operation) {
    setOperation(item)
  }

  const syncIndicator = () => {
    setLoading(true)
    toastId.current = toast.warn('Sincronizando...', {
      autoClose: false,
      position: 'bottom-center',
      toastId: customId
    })
  }

  const syncFinish = () => {
    setLoading(false)
    toast.update(toastId.current, {
      render: 'Sincronizado',
      type: toast.TYPE.INFO,
      autoClose: 500
    })
  }

  const router = useRouter()

  async function syncOperation() {
    if (operation.operation_status_id !== 11 && operation.id) {
      try {
        syncIndicator()

        socket.emit('operation-updating', {
          operationId: operation.id,
          operationCode: operation.code
        })

        await api.put(`/operations/${operation.id}`, {
          company_id: operation.company_id,
          product_id: operation.product_id,
          manager_id: operation.manager_id,
          titles_qtd: operation.titles_qtd,
          expected_value: operation.expected_value,
          paid_value: operation.paid_value,
          day_plus: operation.parameter.day_plus,
          factor_tax:
            parseFloat(normalizeDecimal(operation.parameter.factor_tax)) || 0,
          mdr: parseFloat(normalizeDecimal(operation.parameter.mdr)) || 0,
          formula_id: operation.parameter.formula_id,
          ad_valorem:
            parseFloat(normalizeDecimal(operation.parameter.ad_valorem)) || 0,
          iof: parseFloat(normalizeDecimal(operation.parameter.iof)) || 0,
          iof_additional:
            parseFloat(normalizeDecimal(operation.parameter.iof_additional)) ||
            0,
          iss: parseFloat(normalizeDecimal(operation.parameter.iss)) || 0,
          average_deadline:
            parseFloat(
              normalizeDecimal(operation.parameter.average_deadline)
            ) || 0,
          general_effective_rate:
            parseFloat(
              normalizeDecimal(operation.parameter.general_effective_rate)
            ) || 0,
          date: operation.date
        })

        socket.emit('operation-update-end', {
          operationId: operation.id,
          operationCode: operation.code
        })

        const { data } = await api.get(`/operations/${operation.id}`)
        setOperation(data)
        await loadCalc()
      } catch (err) {
        if (err?.response?.status === 404) {
          router.push('/operations')
        } else {
          socket.emit('operation-update-end', {
            operationId: operation.id,
            operationCode: operation.code
          })
        }
      } finally {
        syncFinish()
      }
    }
  }

  async function loadCalc(operation_id?: number) {
    try {
      const { data: detailData } = await api.get<IDetail>(
        `operations/calc_detail/${operation_id || operation.id}`
      )
      setOperationDetail({
        averageTerm: detailData.averageTerm,
        revenue: detailData.revenue,
        revenueAdValorem: detailData.revenueAdValorem,
        net: detailData.net,
        smallerTitle: detailData.smallerTitle,
        biggerTitle: detailData.biggerTitle,
        qtdTitlesRejected: detailData.qtdTitlesRejected,
        totalTitlesRejected: detailData.totalTitlesRejected,
        loading: false,
        averageTermWithoutDayPlus: detailData.averageTermWithoutDayPlus,
        revenueWithoutDayPlus: detailData.revenueWithoutDayPlus,
        revenueAdValoremWithoutDayPlus:
          detailData.revenueAdValoremWithoutDayPlus,
        netWithoutDayPlus: detailData.netWithoutDayPlus,
        qtdUnnotified: detailData.qtdUnnotified,
        valueUnnotified: detailData.valueUnnotified
      })
    } catch (err) {
      //
    }
  }

  async function refreshOperation(calc?: boolean, operation_id?: number) {
    if (loading) {
      return
    }
    try {
      syncIndicator()
      const { data } = await api.get(
        `/operations/${operation_id || operation.id}`
      )
      console.log('refresh')
      setOperation(data)
      if (calc) {
        await loadCalc(operation_id)
      }
    } catch (err) {
      if (err?.response?.status === 404) {
        router.push('/operations')
      }
    } finally {
      syncFinish()
    }
  }

  return (
    <OperationC.Provider
      value={{
        operation,
        setOperation,
        loading,
        selectOperation,
        syncOperation,
        operationDetail,
        refreshOperation,
        confirmDelete,
        additiveOpen,
        confirmPayment,
        confirmSignatures,
        sendOperationToAnalysis,
        setAdditiveOpen,
        setConfirmDelete,
        setConfirmPayment,
        setConfirmSignatures,
        setSendOperationToAnalysis
      }}
    >
      {children}
    </OperationC.Provider>
  )
}

export const useOperation = (): OperationContext => {
  const context = useContext(OperationC)

  if (!context) {
    throw new Error(
      'The hook useOperation must be used within an OperationProvider'
    )
  }

  return context
}
