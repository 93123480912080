import { Autocomplete } from '@/components/ui/Autocomplete'
import DateView from '@/components/ui/DateView'
import { api, useFetch } from '@/hooks/api'
import {
  normalizeCpfCnpj,
  normalizeCurrency,
  normalizeNumber
} from '@/utils/normalize'
import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { BiTransferAlt } from 'react-icons/bi'
import { FaCheck, FaTimes } from 'react-icons/fa'
import { MdOutlineDoubleArrow } from 'react-icons/md'
import { toast } from 'react-toastify'

// import { Container } from './styles';

export const TransferBetweenAccounts: React.FC<{
  onHide: () => void
}> = ({ onHide }) => {
  const [loading, setLoading] = useState(false)
  const { data: companies } = useFetch('/companies')
  const { data: banks } = useFetch('/banks?all=true')
  const { data: paymentTypes } = useFetch('/payment_types')

  const [origin, setOrigin] = useState({} as any)
  const [destiny, setDestiny] = useState({} as any)
  const [values, setValues] = useState({
    is_same_signal: false
  } as any)

  useEffect(() => {
    if (values.is_same_signal) {
      setDestiny({
        ...destiny,
        type: origin.type
      })
    } else {
      setDestiny({
        ...destiny,
        type: origin.type === 'C' ? 'D' : 'C'
      })
    }
  }, [values.is_same_signal, origin.type])

  async function handleSubmit() {
    if (!origin.event_id) {
      toast.warn('Defina um evento na conta de origem')
      return
    }
    if (!destiny.event_id) {
      toast.warn('Defina um evento na conta de destino')
      return
    }
    try {
      setLoading(true)
      await api.post('/accounts/transfers', {
        ...values,
        value: Number(normalizeNumber(String(values.value))),
        origin_account_id: origin.account_id,
        origin_accounting_event_id: origin.event_id,
        destination_account_id: destiny.account_id,
        destination_accounting_event_id: destiny.event_id,
        type: origin.type
      })

      onHide()
      toast.success('Transferência realizada com sucesso!')
    } catch (error) {
      //
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      show
      centered
      size="lg"
      onHide={() => {
        //
      }}
    >
      <Modal.Body>
        <div className="d-flex justify-content-between">
          <h5 className="m-0">
            <BiTransferAlt /> Transferência entre contas
          </h5>
          <button type="button" onClick={onHide}>
            <FaTimes />
          </button>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-2">
            <Form.Group className="m-0">
              <Form.Label>Data</Form.Label>
              <DateView
                value={values.date}
                onChange={e => {
                  setValues({
                    ...values,
                    date: e
                  })
                }}
              />
            </Form.Group>
          </div>

          <div className="col-md-2">
            <Form.Group className="m-0">
              <Form.Label>Valor</Form.Label>
              <Form.Control
                value={normalizeCurrency(values.value)}
                onChange={e => {
                  setValues({
                    ...values,
                    value: Number(normalizeNumber(e.target.value))
                  })
                }}
              />
            </Form.Group>
          </div>

          <div className="col-md-2">
            <Form.Group className="m-0">
              <Form.Label>Documento</Form.Label>
              <Form.Control
                value={values.document_number}
                onChange={e =>
                  setValues({
                    ...values,
                    document_number: e.target.value
                  })
                }
              />
            </Form.Group>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-5">
            <h6 className="m-0">Origem</h6>
            <div className="p-2 radius-4 bg-gray mt-2">
              <div className="row">
                <div className="col-md-12">
                  <Form.Group className="m-0">
                    <Form.Label>Empresa</Form.Label>
                    <Form.Control
                      value={origin.company_id}
                      onChange={e =>
                        setOrigin({
                          ...origin,
                          company_id: e.target.value
                        })
                      }
                      as="select"
                      id="origin_company"
                    >
                      <option value="">Escolher</option>
                      {companies
                        ?.filter(c => c.active)
                        ?.map(company => (
                          <option value={company.id} key={company.id}>
                            {company.code} - {company.name}
                          </option>
                        ))}
                    </Form.Control>
                  </Form.Group>
                </div>
                <div className="col-md-8">
                  <Form.Group className="m-0">
                    <Form.Label>C/C</Form.Label>
                    <Autocomplete
                      columnTitle="name"
                      columnValue="id"
                      loadResults={async input => {
                        const { data } = await api.get('accounts', {
                          params: {
                            code: input,
                            company_id: origin.company_id,
                            no_default_filters: 1,
                            is_active: 1,
                            all: true
                          }
                        })
                        return data.map(d => ({
                          ...d,
                          name: `${d.code} - ${
                            d.bank
                              ? `[${d.bank?.code}] ${d.bank?.name}`
                              : d.description
                          } | ${
                            d.agency_number && d.agency_digit
                              ? `${d.agency_number}-${d.agency_digit}`
                              : ''
                          } ${
                            d.account_number && d.account_digit
                              ? `${d.account_number}-${d.account_digit}`
                              : ''
                          }`
                        }))
                      }}
                      onSelect={data => {
                        setOrigin({
                          ...origin,
                          account_id: data.id
                        })
                      }}
                      disabled={!origin.company_id}
                    />
                  </Form.Group>
                </div>
                <div className="col-md-4">
                  <Form.Group className="m-0">
                    <Form.Label>Tp. Lanç.</Form.Label>
                    <Form.Control
                      as="select"
                      value={origin.type}
                      onChange={e =>
                        setOrigin({
                          ...origin,
                          type: e.target.value
                        })
                      }
                      id="origin_type"
                    >
                      <option value="">Escolher</option>
                      <option value="D">Débito</option>
                      <option value="C">Crédito</option>
                    </Form.Control>
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <Form.Group className="m-0">
                    <Form.Label>Evento</Form.Label>
                    <Autocomplete
                      columnTitle="code"
                      columnValue="id"
                      loadResults={async input => {
                        const { data } = await api.get('/accounting_events', {
                          params: {
                            code: input,
                            per_page: 6,
                            page: 1
                          }
                        })
                        return data?.data?.map(d => ({
                          ...d,
                          code: `${d?.code} - ${d?.description}`
                        }))
                      }}
                      onSelect={data => {
                        setOrigin({
                          ...origin,
                          event_id: data?.id
                        })
                      }}
                    />
                  </Form.Group>
                </div>
              </div>
            </div>
            <Form.Group className="m-0">
              <Form.Check
                label="Mesmo sinal nos lançamentos"
                checked={values.is_same_signal}
                onClick={() =>
                  setValues({
                    ...values,
                    is_same_signal: !values.is_same_signal
                  })
                }
                className="mt-2"
              />
            </Form.Group>
          </div>
          <div className="col-md-2 d-flex justify-content-center align-items-center flex-column">
            {destiny.type === 'C' && origin.type === 'D' && (
              <MdOutlineDoubleArrow size="2.5rem" className="text-muted" />
            )}
            {destiny.type === 'D' && origin.type === 'C' && (
              <MdOutlineDoubleArrow
                size="2.5rem"
                style={{ transform: 'rotate(180deg)' }}
                className="text-muted"
              />
            )}
            {origin.type && destiny.type && destiny.type === origin.type && (
              <span>Mesmo Sinal</span>
            )}
          </div>
          <div className="col-md-5">
            <h6 className="m-0">Destino</h6>
            <div className="p-2 radius-4 bg-gray mt-2">
              <div className="row">
                <div className="col-md-12">
                  <Form.Group className="m-0">
                    <Form.Label>Empresa</Form.Label>
                    <Form.Control
                      value={destiny.company_id}
                      onChange={e =>
                        setDestiny({
                          ...destiny,
                          company_id: e.target.value
                        })
                      }
                      as="select"
                      id="destiny_company"
                    >
                      <option value="">Escolher</option>
                      {companies
                        ?.filter(c => c.active)
                        ?.map(company => (
                          <option value={company.id} key={company.id}>
                            {company.code} - {company.name}
                          </option>
                        ))}
                    </Form.Control>
                  </Form.Group>
                </div>
                <div className="col-md-8">
                  <Form.Group className="m-0">
                    <Form.Label>C/C</Form.Label>
                    <Autocomplete
                      columnTitle="name"
                      columnValue="id"
                      loadResults={async input => {
                        const { data } = await api.get('accounts', {
                          params: {
                            code: input,
                            company_id: destiny.company_id,
                            no_default_filters: 1,
                            is_active: 1,
                            all: true
                          }
                        })
                        return data.map(d => ({
                          ...d,
                          name: `${d.code} - ${
                            d.bank
                              ? `[${d.bank?.code}] ${d.bank?.name}`
                              : d.description
                          } | ${
                            d.agency_number && d.agency_digit
                              ? `${d.agency_number}-${d.agency_digit}`
                              : ''
                          } ${
                            d.account_number && d.account_digit
                              ? `${d.account_number}-${d.account_digit}`
                              : ''
                          }`
                        }))
                      }}
                      onSelect={data => {
                        setDestiny({
                          ...destiny,
                          account_id: data.id
                        })
                      }}
                      disabled={!destiny.company_id}
                    />
                  </Form.Group>
                </div>
                <div className="col-md-4">
                  <Form.Group className="m-0">
                    <Form.Label>Tp. Lanç.</Form.Label>
                    <Form.Control
                      value={destiny.type || ''}
                      as="select"
                      id="destiny_type"
                      disabled
                    >
                      <option value=""></option>
                      <option value="D">Débito</option>
                      <option value="C">Crédito</option>
                    </Form.Control>
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <Form.Group className="m-0">
                    <Form.Label>Evento</Form.Label>
                    <Autocomplete
                      columnTitle="code"
                      columnValue="id"
                      loadResults={async input => {
                        const { data } = await api.get('/accounting_events', {
                          params: {
                            code: input,
                            per_page: 6,
                            page: 1
                          }
                        })
                        return data?.data?.map(d => ({
                          ...d,
                          code: `${d?.code} - ${d?.description}`
                        }))
                      }}
                      onSelect={data => {
                        setDestiny({
                          ...destiny,
                          event_id: data?.id
                        })
                      }}
                    />
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 mt-2">
            <Form.Group className="m-0">
              <Form.Label>CPF/CNPJ</Form.Label>
              <Form.Control
                value={values.recipient_document_number}
                onChange={e =>
                  setValues({
                    ...values,
                    recipient_document_number: normalizeCpfCnpj(e.target.value)
                  })
                }
              />
            </Form.Group>
          </div>
          <div className="col-md-5 mt-2">
            <Form.Group className="m-0">
              <Form.Label>Histórico</Form.Label>
              <Form.Control
                value={values.history}
                onChange={e =>
                  setValues({
                    ...values,
                    history: e.target.value
                  })
                }
              />
            </Form.Group>
          </div>
          <div className="col-md-2 mt-2">
            <Form.Group className="m-0">
              <Form.Label>Data Comp.</Form.Label>
              <DateView
                value={values.compensation_date}
                onChange={e => {
                  setValues({
                    ...values,
                    compensation_date: e
                  })
                }}
              />
            </Form.Group>
          </div>
          <div className="col-md-2 mt-2">
            <Form.Group className="mb-2">
              <Form.Label>Tp. Pagamento:</Form.Label>
              <Form.Control
                value={values.payment_type_id}
                onChange={e =>
                  setValues({
                    ...values,
                    payment_type_id: Number(e.target.value)
                  })
                }
                as="select"
              >
                <option value="">Escolher</option>
                {paymentTypes?.map(e => (
                  <option value={e.id} key={e.id}>
                    {e.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>
          {(values.payment_type_id === 1 ||
            values.payment_type_id === 2 ||
            values.payment_type_id === 3 ||
            values.payment_type_id === 4) && (
            <div className="col-md-12 mt-3">
              <h6 className="m-0">Dados do Favorecido</h6>
              <div className="bg-gray p-2 radius-4 mt-2">
                <div className="row">
                  <div className="col-md-4">
                    <Form.Group className="m-0">
                      <Form.Label>Nome</Form.Label>
                      <Form.Control
                        value={values.recipient_name}
                        onChange={e =>
                          setValues({
                            ...values,
                            recipient_name: e.target.value
                          })
                        }
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-1">
                    <Form.Group className="m-0">
                      <Form.Label>Comp.</Form.Label>
                      <Form.Control
                        value={values.compensation_code}
                        onChange={e =>
                          setValues({
                            ...values,
                            compensation_code: e.target.value
                          })
                        }
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-2">
                    <Form.Group className="m-0">
                      <Form.Label>Banco</Form.Label>
                      <Form.Control
                        as="select"
                        value={values.bank_id}
                        onChange={e =>
                          setValues({
                            ...values,
                            bank_id: e.target.value
                          })
                        }
                      >
                        <option value="">Escolher</option>
                        {banks?.map(b => (
                          <option value={b.id} key={b.id}>
                            {b.code} - {b.name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </div>
                  <div className="col-md-2">
                    <Form.Group className="m-0">
                      <Form.Label>Agência</Form.Label>
                      <div className="d-flex align-items-center">
                        <Form.Control
                          value={values.agency_number}
                          onChange={e =>
                            setValues({
                              ...values,
                              agency_number: normalizeNumber(e.target.value)
                            })
                          }
                        />
                        <span className="ml-1 mr-1">-</span>
                        <Form.Control
                          style={{ width: '25px' }}
                          value={values.agency_digit}
                          onChange={e =>
                            setValues({
                              ...values,
                              agency_digit: normalizeNumber(e.target.value[0])
                            })
                          }
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-3">
                    <Form.Group className="m-0">
                      <Form.Label>Conta</Form.Label>
                      <div className="d-flex align-items-center">
                        <Form.Control
                          value={values.account_number}
                          onChange={e =>
                            setValues({
                              ...values,
                              account_number: normalizeNumber(e.target.value)
                            })
                          }
                        />
                        <span className="ml-1 mr-1">-</span>
                        <Form.Control
                          style={{ width: '25px' }}
                          value={values.account_digit}
                          onChange={e =>
                            setValues({
                              ...values,
                              account_digit: normalizeNumber(e.target.value[0])
                            })
                          }
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="d-flex justify-content-center mt-3">
          <Button disabled={loading} onClick={handleSubmit} type="button">
            <FaCheck /> Salvar
          </Button>
          <Button
            type="button"
            variant="outline-secondary"
            className="ml-2"
            onClick={onHide}
          >
            <FaTimes /> Cancelar
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}
