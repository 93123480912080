import { Theme } from './styles'

const dark: Theme = {
  name: 'dark',
  colors: {
    background: '#0b1b3a',
    text: '#eee',
    primary: '#3757a1',
    primaryHover: '#234186',
    secondary: '#00a6d2',
    danger: '#e04848'
  }
}

export default dark
