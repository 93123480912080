import styled from 'styled-components'

export const Container = styled.div`
  .toolbars {
    transform: rotate(90deg);
    transform-origin: right top;
    position: fixed !important;
    bottom: 10px !important;
    right: 0 !important;
    z-index: 999;
    display: flex;
    justify-content: flex-end;
    button {
      padding: 5px 10px;
      border: 0;
      color: #3757a1;
      background: #fff;
      border-radius: 0.4rem;
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
      box-shadow: 0 0.1rem 1rem 0.25rem rgb(0 0 0 / 5%) !important;
      display: flex;
      align-items: center;
      svg {
        font-size: 1rem;
        margin-right: 5px !important;
      }
    }
  }
`
