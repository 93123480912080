import { createContext, useState, useContext, useEffect } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import {
  FaCheck,
  FaExclamationTriangle,
  FaInfoCircle,
  FaTimes
} from 'react-icons/fa'

interface ModalProps {
  title: string
  message?: string | JSX.Element
  options?: {
    size?: 'sm' | 'lg' | 'xl'
    onConfirm?: () => void
    onCancel?: () => void
    cancelText?: string
  }
}

interface ThemeContext {
  error: (data: ModalProps) => void
  warning: (data: ModalProps) => void
  info: (data: ModalProps) => void
  show: (data: ModalProps) => void
  requestPassword: (onConfirm: () => any) => void
}

const Theme = createContext<ThemeContext>({} as ThemeContext)

export const ModalProvider: React.FC = ({ children }) => {
  const [modalProps, setModalProps] = useState({} as ModalProps)
  const [modalOpen, setModalOpen] = useState(false)
  const [theme, setTheme] = useState<
    'error' | 'warning' | 'info' | 'show' | 'password' | null
  >(null)

  useEffect(() => {
    if (!modalOpen) {
      setTimeout(() => {
        setModalProps({} as ModalProps)
      }, 300)
    }
  }, [modalOpen])

  const error = (data: ModalProps) => {
    setTheme('error')
    setModalProps(data)
    setModalOpen(true)
  }

  const warning = (data: ModalProps) => {
    setTheme('warning')
    setModalProps(data)
    setModalOpen(true)
  }

  const info = (data: ModalProps) => {
    setTheme('info')
    setModalProps(data)
    setModalOpen(true)
  }

  const show = (data: ModalProps) => {
    setTheme('show')
    setModalProps(data)
    setModalOpen(true)
  }

  const requestPassword = (onConfirm: () => any) => {
    setTheme('password')
    setModalProps({
      title: 'Senha Requisitada',
      message: 'Para completar esta ação é necessário informar sua senha',
      options: {
        onConfirm,
        onCancel: () => {
          //
        }
      }
    })
    setModalOpen(true)
  }

  const dimiss = () => {
    if (modalProps?.options?.onCancel) {
      modalProps?.options?.onCancel()
    }
    setModalOpen(false)
  }

  return (
    <Theme.Provider value={{ error, warning, info, show, requestPassword }}>
      <Modal
        show={modalOpen}
        onHide={dimiss}
        centered
        size={modalProps?.options?.size || 'sm'}
      >
        <Modal.Body className="text-center">
          <div className="d-flex justify-content-end">
            <button type="button" className="text-muted" onClick={dimiss}>
              <FaTimes />
            </button>
          </div>
          {theme === 'info' && (
            <FaInfoCircle className="text-primary mb-3" size="3rem" />
          )}
          {theme === 'warning' && (
            <FaExclamationTriangle className="text-warning mb-3" size="3rem" />
          )}
          {theme === 'error' && (
            <FaInfoCircle className="text-danger mb-3" size="3rem" />
          )}
          <h5>{modalProps?.title}</h5>
          {modalProps?.message && (
            <div className="mb-3">
              {typeof modalProps?.message === 'string' && (
                <p>{modalProps?.message}</p>
              )}
              {typeof modalProps?.message !== 'string' && modalProps?.message}
            </div>
          )}
          {theme === 'password' && (
            <Form.Control
              placeholder="Senha"
              type="password"
              className="mb-3 mt-3"
            />
          )}
          <div className="d-flex justify-content-center">
            <Button
              type="button"
              variant="outline-primary"
              onClick={() => {
                if (modalProps?.options?.onConfirm) {
                  modalProps?.options?.onConfirm()
                }
                setModalOpen(false)
              }}
            >
              <FaCheck />{' '}
              {modalProps?.options?.onConfirm && theme !== 'password'
                ? 'Sim'
                : 'OK'}
            </Button>
            {modalProps?.options?.onCancel && (
              <Button
                onClick={dimiss}
                className="ml-2"
                variant="outline-secondary"
                type="button"
              >
                <FaTimes /> {modalProps?.options?.cancelText || 'Cancelar'}
              </Button>
            )}
          </div>
        </Modal.Body>
      </Modal>
      {children}
    </Theme.Provider>
  )
}

export const useModal = (): ThemeContext => {
  const context = useContext(Theme)

  if (!context) {
    throw new Error('The hook useModal must be used within an ModalProvider')
  }

  return context
}
