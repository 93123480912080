import {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect
} from 'react'
import { MenuProvider } from './useMenu'

import { useRouter } from 'next/router'
import { api } from '@/hooks/api'
import { LayoutSystem } from '@/layouts/System'

import { toast } from 'react-toastify'

import { AuthPage } from '@/components/pages/AuthPage'
import { LoadingPage } from '@/components/pages/LoadingPage'
import { setCookie } from '@/utils/cookie'

interface AuthResponse {
  token: string
  user: User
  state: 'mfa_token_required' | 'logged_in'
}

interface AuthState {
  token: string
  user: User
}

interface SignInCredentials {
  email: string
  password: string
  mfaToken?: string
  signedOutBecauseOfInactivity?: boolean
}

interface AuthContextData {
  token: string
  user: User
  isManager: boolean
  isCoordinator: boolean
  permissions: PermissionAuth[]
  signIn(
    credentials: SignInCredentials
  ): Promise<'mfa_token_required' | 'logged_in'>
  signOut(): void
  signOutBecauseOfInactivity(): void
  updateUser(user: User): void
  sendRecovery(email: string): Promise<void>
  recovery(token: string, password: string): Promise<void>
}

const Auth = createContext<AuthContextData>({} as AuthContextData)

const interceptorToastId = 'axios-interceptor'

export const AuthProvider: React.FC = ({ children }) => {
  const [initialLoading, setInitialLoading] = useState(true)
  const [permissions, setPermissions] = useState<PermissionAuth[]>([])
  const router = useRouter()

  const [data, setData] = useState<AuthState>({} as AuthState)
  const [limitations, setLimitations] = useState({
    isManager: false,
    isCoordinator: false
  })

  const loadPermissions = useCallback(async () => {
    try {
      const { data } = await api.get<
        {
          companyId: number
          permissions: string[]
        }[]
      >('/users/me/permissions')
      const arr: PermissionAuth[] = []
      data.forEach(item => {
        item.permissions.forEach(permission => {
          arr.push({
            company_id: item.companyId,
            slug: permission
          })
        })
      })
      setPermissions(arr)
    } catch (err) {
      //
    }
  }, [])

  const { t } = router.query

  useEffect(() => {
    if (!t) {
      const token = localStorage.getItem('@BFCDigital:token')
      const user = localStorage.getItem('@BFCDigital:user')

      if (token && user) {
        api.defaults.headers.Authorization = `Bearer ${token}`

        setCookie('token', token, 1)

        setData({ token, user: JSON.parse(user) })
        loadPermissions()
      }
    } else {
      localStorage.removeItem('@BFCDigital:token')
      localStorage.removeItem('@BFCDigital:user')
      setData({} as any)
      router.push(`/?t=${t}`)
    }
    setInitialLoading(false)
  }, [t])

  useEffect(() => {
    async function handle() {
      const { data: dataMe } = await api.get('/me', {
        headers: {
          Authorization: `Bearer ${data.token}`
        }
      })

      setLimitations({
        isManager: typeof dataMe.manager?.id === 'number',
        isCoordinator: typeof dataMe.coordinator?.id === 'number'
      })
    }
    if (data.token) {
      handle()
    }
  }, [data.token])

  const sendRecovery = useCallback(async (email: string) => {
    await api.post('/forgot-password', {
      email
    })

    toast.success('E-mail de recuperação enviado')

    router.push('/')
  }, [])

  const recovery = useCallback(async (t: string, password: string) => {
    await api.post<AuthResponse>(
      'reset-password',
      {
        password
      },
      {
        params: {
          token: t
        }
      }
    )

    toast.info('Senha atualizada com sucesso')

    router.push('/')
  }, [])

  const signIn = useCallback(
    async ({ email, password, mfaToken, signedOutBecauseOfInactivity }) => {
      try {
        const response = !mfaToken
          ? await api.post<AuthResponse>('/sessions', {
              email,
              password,
              mfaToken,
              signedOutBecauseOfInactivity
            })
          : await api.post<AuthResponse>('/sessions-token', {
              email,
              password,
              mfaToken
            })

        const { token, user, state } = response.data

        if (state === 'mfa_token_required') {
          return state
        }

        if (typeof window !== 'undefined') {
          localStorage.setItem('@BFCDigital:token', token)
          localStorage.setItem('@BFCDigital:user', JSON.stringify(user))
        }

        api.defaults.headers.Authorization = `Bearer ${token}`

        setCookie('token', token, 1)

        setData({ token, user })
        loadPermissions()
      } catch (error) {
        toast.error(error.message)
      }
    },
    [router]
  )

  const signOut = useCallback(() => {
    if (typeof window !== 'undefined') {
      localStorage.removeItem('@BFCDigital:token')
      localStorage.removeItem('@BFCDigital:user')
      localStorage.removeItem('@BFCDigital:oat')
    }

    setData({} as AuthState)
  }, [])

  const signOutBecauseOfInactivity = useCallback(() => {
    if (typeof window !== 'undefined') {
      localStorage.removeItem('@BFCDigital:token')
      // localStorage.removeItem('@BFCDigital:user')
      localStorage.removeItem('@BFCDigital:oat')
    }

    setData(data => ({ user: data.user, token: '' } as AuthState))
  }, [])

  useEffect(() => {
    api.interceptors.response.use(
      response => {
        return response
      },
      error => {
        if (error?.response?.status === 401) {
          toast.warn('Você está deslogado, entre novamente', {
            toastId: interceptorToastId
          })
          signOut()
        } else if (error?.response?.status === 403) {
          if (
            error?.response?.data?.message ===
            'Acesso restrito no dia ou horário atual.'
          ) {
            signOut()
          }
          toast.error(
            error?.response?.data?.message ||
              'Você não tem permissão à este recurso',
            {
              toastId: interceptorToastId
            }
          )
        } else if (error?.response?.status === 404) {
          if (error?.response?.data?.message) {
            toast.error(error?.response?.data?.message)
          } else {
            toast.error('Recurso não encontrado')
          }
        } else if (
          error?.response?.status === 400 ||
          error?.response?.status === 422
        ) {
          if (error?.response?.data.errors) {
            if (Array.isArray(error?.response.data.errors)) {
              error?.response.data.errors.forEach(d => {
                toast.error(`${d.field ? `${d.field}: ` : ''}${d.message}`)
              })
            } else {
              toast.error(error?.response.data.errors.message)
            }
          } else {
            if (Array.isArray(error?.response.data)) {
              error?.response.data.forEach(d => {
                toast.error(d.message)
              })
            } else {
              toast.error(error?.response.data.message)
            }
          }
        } else if (error?.response?.status === 500) {
          toast.dark(
            'Ocorreu um erro inesperado em nosso sistema, contate o suporte'
          )
        } else if (error?.response?.status === 502) {
          toast.dark('Sistema fora do ar, contate o suporte')
        }

        return Promise.reject(error.response)
      }
    )
  }, []) // eslint-disable-line

  const updateUser = useCallback(
    (user: User) => {
      localStorage.setItem('@BFCDigital:user', JSON.stringify(user))
      setData({ token: data.token, user })
      setCookie('token', data.token, 1)
    },
    [setData, data.token]
  )

  return (
    <Auth.Provider
      value={{
        token: data.token,
        recovery,
        sendRecovery,
        user: data.user,
        signIn,
        signOut,
        signOutBecauseOfInactivity,
        updateUser,
        permissions,
        isManager: limitations.isManager,
        isCoordinator: limitations.isCoordinator
      }}
    >
      {initialLoading && <LoadingPage />}
      {data.token && data.user && router.pathname !== '/404' ? (
        <MenuProvider>
          <LayoutSystem>{children}</LayoutSystem>
        </MenuProvider>
      ) : null}
      {!data.token && !data.user && router.pathname !== '/404' ? (
        <AuthPage />
      ) : null}
      {!data.token && data.user && router.pathname !== '/404' ? (
        <AuthPage signedOutBecauseOfInactivity />
      ) : null}
      {router.pathname === '/404' ? children : null}
    </Auth.Provider>
  )
}

export const useAuth = (): AuthContextData => {
  const context = useContext(Auth)

  if (!context) {
    throw new Error('The hook useAuth must be used within an AuthProvider')
  }

  return context
}
